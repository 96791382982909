import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { alertActions } from '_store';
import { history, fetchWrapper } from '_helpers';
// import { useSelector } from 'react-redux';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        value: JSON.parse(localStorage.getItem('auth'))
        // value: getAuth()
    }
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${process.env.FRONTEND_URL_PORT}/users`;

    return {
        login: login(),
        logout: logout(),
        forgot: forgot(),
        reset: reset()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function ({ email, password }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    // const user = await fetchWrapper.post(`http://192.168.100.132/auth/login`, { email, password });
                    const user = await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, { email, password });

                    // set auth user in redux state
                    dispatch(authActions.setAuth(user));
                    // console.log("user",user);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', JSON.stringify(user));
                    if (user.data && user.data.user && user.data.user.shopifyAccessToken && user.data.user.shopifyDate && user.data.user.shopifyLink) {
                       var data = {
                            shopifyAccessToken: user.data.user.shopifyAccessToken,
                            date: user.data.user.shopifyDate,
                            linkk: user.data.user.shopifyLink
                        }
                        localStorage.setItem('import', JSON.stringify(data));
                    }
                    dispatch(alertActions.success({ message: 'Login successful', showAfterRedirect: true }));
                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }
    function forgot() {
        return createAsyncThunk(
            `${name}/forgot`,
            async function ({ email }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    const user = await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/auth/forgotPassword`, { email });
                    console.log(user);

                    dispatch(alertActions.success(`Check your email address for the password reset link.`));

                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }
    function reset() {
        return createAsyncThunk(
            `${name}/reset`,
            async function ({ password, auth, userID }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    console.log(password, auth);
                    const user = await fetchWrapper.put(`${process.env.REACT_APP_BACKEND_URL}/auth/resetPassword`, { password: password, userID: userID }, auth);
                    console.log(user);

                    dispatch(alertActions.success(`Password reset successfully`));

                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem('auth');
                localStorage.removeItem('import');
                localStorage.removeItem('role');
                localStorage.removeItem('filter');
                history.navigate('/account/login');
            }
        );
    }
}