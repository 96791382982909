import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import Styles from "../account/style.module.css";
import { locationActions, alertActions } from '_store';
import { Loader } from '../../_components/loader';


export { AddEditLocation };

function AddEditLocation({ handleClose, id }) {
    // console.log("id", id);
    const [disabled, setDisabled] = useState(false)
    // Add these variables to your component to track the state

    const [title, setTitle] = useState();
    const [label, setLabel] = useState();
    const [modifiedphone, setmodifiedphone] = useState("")
    const [lastphone, setLastPhone] = useState();

    const dispatch = useDispatch();
    const location = useSelector(x => x.location.list);
    // console.log("locations -----", location);
    // const data = locationData

    let phoneRejex =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    // form validation rules 
    const validationSchema = Yup.object().shape({
        // phoneNo: Yup.string()
        //     .required("Phone Number is required")
        //     .matches(phoneRejex, "Phone Number is not valid"),
        zipCode: Yup.string()
            .required('Zip code is required'),
        email: Yup.string()
            .required('Email is required').email(),
        address: Yup.string()
            .required('Address is required'),
        name: Yup.string()
            .required('Name is required'),
        // specialInstructions: Yup.string()
        //     .required('Instructions is required'),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string()
            .required('State is required'),
        // country: Yup.string()
        //     .required('Country is required'),
        status: Yup.string()
            .required('Status is required'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState , setValue} = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            setTitle('Edit Pickup Location');
            setLabel('UPDATE');
            if(location.value.data.locations[0].phoneNo){
                setmodifiedphone(convertNumber(location.value.data.locations[0].phoneNo))
                setValue("shipping_address.phone", location.value.data.locations[0].phoneNo)
            }
            // fetch location details and
            // populate form fields with reset()
            // and filter by props id

            dispatch(locationActions.getAll())
                .then(res => {
                    const locationArr = res?.payload?.data?.locations
                    // console.log("locationArr", res);
                    let item = locationArr.filter((c) => {
                        const obj = c._id === id
                        return obj
                    })
                    // console.log(item[0]);
                    console.log('item[0]', item[0]);
                    reset(item[0])
                })
        } else {
            setTitle('Add New Pickup Location');
            setLabel('ADD PICKUP LOCATION');
        }
    }, []);

    function convertNumber(data) {
        var numericString = data.replace(/[^\d]/g, '');
        if (numericString.length > 11) {
          return numericString;
        }
        if (numericString && numericString.length) {
        }
        if (modifiedphone && modifiedphone.length) {
        }
        if (lastphone && lastphone.length) {
        }
        if (modifiedphone && data.length < modifiedphone.length) {
          if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-") {
            return `${data.slice(0, data.length - 1)}`;
          }
          else {
            return `${data.slice(0, data.length)}`;
          }
        }
        else {
          if (numericString.charAt(0) == '1') {
            numericString = numericString.slice(1);
          }
          if (numericString.length >= 2) {
            setLastPhone(numericString)
            return `+1 (${numericString.slice(0, 3)}) ${numericString.slice(3, 6)}-${numericString.slice(6)}`;
          }
          if (numericString.length >= 2) {
            if (numericString.charAt(0)) {
              numericString = numericString.slice(1);
            }
            var format2 = ''
            var format3 = ''
            if (numericString.length > 3) {
              format2 = numericString.slice(3, 6)
              format3 = numericString.slice(6)
            }
    
            if (lastphone && lastphone.length) {
            }
            if (modifiedphone && modifiedphone.length) {
            }
            if (modifiedphone && modifiedphone.length && lastphone && lastphone.length && lastphone.length <= modifiedphone.length) {
              setLastPhone(numericString)
              return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
            }
            else {
              if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == " " || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == ")" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "(") {
                setLastPhone(numericString)
                return `${data.slice(0, data.length - 1)}`;
              }
              else {
                setLastPhone(numericString)
                return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
              }
            }
          }
        }
      }

    async function onSubmit(data) {
        var phone = modifiedphone ? modifiedphone.replace("+1 (", "").replace(") ", "").replace("-", "") : data.shipping_address.phone.replace("+1 (", "").replace(") ", "").replace("-", "");
        dispatch(alertActions.clear());
        setDisabled(true)
        try {
            // create or update user based on id param
            let message;
            if (id) {
                console.log("data", data);
                let obj = {
                    "status": data.status,
                    "email": data.email,
                    "address": data.address,
                    "phoneNo": phone,
                    "zipCode": data.zipCode,
                    "city": data.city,
                    "state": data.state,
                    "specialInstructions": data.specialInstructions,
                    "name": data.name,
                }
                // console.log("data front", obj);
                await dispatch(locationActions.update({ id, obj })).unwrap();
                message = 'Location has been updated';
            } else {
                data.phoneNo = data.shipping_address.phone.replace("+1 (", "").replace(") ", "").replace("-", "")
                await dispatch(locationActions.addLocation(data)).unwrap();
                message = 'Location added successfully';
            }

            // redirect to location list with success message
            dispatch(locationActions.getAll());
            handleClose(false)
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
        setTimeout(() => {
            setDisabled(false)
        }, 2500);
    }

    return (
        <div className={Style.sections_edit}>
            <div className={Style.main_container_edit}>
                <h1 style={{ textAlign: "center" }} className={Style.title}>{title}</h1>
                {!(location?.loading || location?.error) &&
                    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "50px" }}>
                        <div className={Styles.input_wrapper}>
                            <input placeholder="Pickup Address" name="address" type="text" {...register('address')} className={`${errors.address ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errors.address?.message}</div>
                        </div>
                        <div className={Styles.input_row}>
                            {/* <div className={Styles.input_wrapper}>
                                <input placeholder="Country" name="country" type="text" {...register('country')} className={`${errors.country ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.country?.message}</div>
                            </div> */}
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Province' name="state" type="text" {...register('state')} className={`${errors.state ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.state?.message}</div>
                            </div>
                        </div>
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <input placeholder="City" name="city" type="text" {...register('city')} className={`${errors.city ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.city?.message}</div>
                            </div>
                            <div className={Styles.input_wrapper}>
                                <input placeholder="Postal Code" name="zipCode" type="text" {...register('zipCode')} className={`${errors.zipCode ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.zipCode?.message}</div>
                            </div>
                        </div>
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Name' name="name" type="text" {...register('name')} className={`${errors.name ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.name?.message}</div>
                            </div>
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Phone Number' name="phoneNo" type="text" value={modifiedphone}
                                    onChange={(e) => {
                                        var mphon = convertNumber(e.target.value)
                                        setmodifiedphone(mphon)
                                        setValue("shipping_address.phone", mphon)
                                    }} className={`${errors.phoneNo ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.phoneNo?.message}</div>
                            </div>
                        </div>
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Email Address' name="email" type="text" {...register('email')} className={`${errors.email ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.email?.message}</div>
                            </div>
                            <div className={Styles.input_wrapper}>
                                <select className="input_location" name='status' placeholder='Add Status' {...register("status")}>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <div className={Styles.invalid_feedback}>{errors.status?.message}</div>
                            </div>
                        </div>
                        <div className={Styles.input_wrapper}>
                            <input placeholder="Special Intructions" name="specialInstructions" type="text" {...register('specialInstructions')} className={`${errors.specialInstructions ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errors.specialInstructions?.message}</div>
                        </div>
                        <div className={Styles.input_row} style={{ gap: "20px" }}>
                            <button type="submit" disabled={disabled} className={Styles.theme_btn}>
                                {isSubmitting && disabled && <span className="spinner"></span>}
                                {label}
                            </button>
                        </div>
                    </form>
                }
                {location?.loading &&
                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <Loader />
                    </div>
                }
                {location?.error &&
                    <div class="text-center m-5">
                        <div class="text-danger">Error loading user: {location.error}</div>
                    </div>
                }
            </div>
        </div>
    );
}
