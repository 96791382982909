import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useSpring, animated } from '@react-spring/web';
import { AddEditLocation } from './AddEditLocation';
import { locationActions } from '_store';
// import { locationData } from '_helpers/dummy'
export { Location };

//<Model Styling>
const Fade = React.forwardRef(function Fade(props, ref) {
	const {
		children,
		in: open,
		onClick,
		onEnter,
		onExited,
		ownerState,
		...other
	} = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null, true);
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

Fade.propTypes = {
	children: PropTypes.element.isRequired,
	in: PropTypes.bool,
	onClick: PropTypes.any,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
	ownerState: PropTypes.any,
};
//<Model Styling/>

function Location() {

	const [open, setOpen] = React.useState(false);
	const [rows, setRows] = React.useState([]);
    const [modifiedphone, setmodifiedphone] = useState("")
    const [lastphone, setLastPhone] = useState();
	const [editLocation, setEditLocation] = React.useState('');
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const mobile = useSelector(x => x.mobile.value)

	const location = useSelector(x => x.location.list);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(locationActions.getAll());
	}, []);

	//SET GRID ROW
	useEffect(() => {
		const locationData = location?.value?.data?.locations
		// console.log("locationsData", location);

		if (locationData?.length > 0) {
			const items = locationData?.map((data,i) => {
				return {
					"id": data._id,
					"sNo": `Location ${data.uniqueId}`,
					"address": data.completeAddress,
					"email": data.email,
					'name' :data.name,
					'country': data.country,
					"status": data.status,
					'state': data.state,
					'city': data.city,
					"phoneNo": convertNumber(data.phoneNo),
					"zipCode": data.zipCode,
					url: `edit/${data.id}`
				}
			})
			setRows(items)
		}


	}, [location]);

	//GRID COLUMN
	const columns = [
		{
			field: 'sNo', headerName: 'Location ID',
			minWidth: 120,
			// maxWidth: 300,
			flex: .5,
		},
		{
			field: 'address',
			headerName: 'Pickup Address',
			minWidth: 400,
			flex: 1,
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 100,
			flex: .8
		},
		{
			field: 'phoneNo',
			headerName: 'Phone No.',
			minWidth: 10,
			flex: .8
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 150,
			flex: 1
		},
		// {
		// 	field: 'country',
		// 	headerName: 'Country',
		// 	minWidth: 100,
		// 	flex: .8
		// },
		// {
		// 	field: 'state',
		// 	headerName: 'state',
		// 	minWidth: 100,
		// 	flex: .8
		// },
		// {
		// 	field: 'city',
		// 	headerName: 'City',
		// 	minWidth: 100,
		// 	flex: .8
		// },
		// {
		// 	field: 'zipCode',
		// 	headerName: 'Zip Code',
		// 	minWidth: 150,
		// 	flex: 1,
		// 	align: 'left',
		// 	headerAlign: 'left'
		// },
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			flex: 1,
			align: 'left',
			headerAlign: 'left',
			renderCell: (params) => (
				<span className={`${params.value.replaceAll(' ', '')} status`}>{params.value}</span>
			)
		},
		{
			field: "action",
			headerName: "Action",
			renderCell: (cellValues) => {
				return <Link onClick={() => { setEditLocation(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit</Link>;
			},
			minWidth: 150,
			filterable: false
		}
	];

	// Grid Style
	const grigStyle =
	{
		'.MuiDataGrid-columnSeparator': {
			display: 'none',
		},
		".MuiDataGrid-overlayWrapperInner": {
			maxHeight: "500px",
			minHeight: "500px"
		},
		'.MuiDataGrid-overlayWrapper': {
			height: "100%!important"
		},
		'&.MuiDataGrid-root': {
			border: '',
		},
		'.MuiDataGrid-cellContent': {
			fontFamily: "AxiformaRegular",
			fontSize: "14px",
			color: '#303030'
		},
		'.MuiDataGrid-columnHeaderTitleContainerContent': {
			fontFamily: 'AxiformaSemiBold',
			color: '#000',
			fontSize: "14px",
		}
	}

	function NoRowsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}

	function NoResultsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}

	function convertNumber(data) {
        var numericString = data.replace(/[^\d]/g, '');
        if (numericString.length > 11) {
            return numericString;
        }
        if (numericString && numericString.length) {
        }
        if (modifiedphone && modifiedphone.length) {
        }
        if (lastphone && lastphone.length) {
        }
        if (modifiedphone && data.length < modifiedphone.length) {
            if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-") {
                return `${data.slice(0, data.length - 1)}`;
            }
            else {
                return `${data.slice(0, data.length)}`;
            }
        }
        else {
            if (numericString.charAt(0) == '1') {
                numericString = numericString.slice(1);
            }
            if (numericString.length >= 2) {
                return `+1 (${numericString.slice(0, 3)}) ${numericString.slice(3, 6)}-${numericString.slice(6)}`;
            }
            if (numericString.length >= 2) {
                if (numericString.charAt(0)) {
                    numericString = numericString.slice(1);
                }
                var format2 = ''
                var format3 = ''
                if (numericString.length > 3) {
                    format2 = numericString.slice(3, 6)
                    format3 = numericString.slice(6)
                }

                if (lastphone && lastphone.length) {
                }
                if (modifiedphone && modifiedphone.length) {
                }
                if (modifiedphone && modifiedphone.length && lastphone && lastphone.length && lastphone.length <= modifiedphone.length) {
                    return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
                }
                else {
                    if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == " " || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == ")" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "(") {
                        return `${data.slice(0, data.length - 1)}`;
                    }
                    else {
                        return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
                    }
                }
            }
        }
    }

	return (
		<div className={Style.sections} style={{ marginLeft: mobile ? mobile.class : "290px" }}>
			<div className={Style.main_container} >
				<div className={Style.head}>
					<h1 className={Style.title}>Pickup Locations</h1>
					<div className={Style.add_search}>
						<Link onClick={() => {
							setEditLocation('')
							handleOpen()
						}} className={Style.add_location}>
							<AddIcon color='#fff' />
							<span className={Style.link}>Add new pickup location</span>
						</Link>
					</div>
				</div>

				<div className={Style.userlist}>
					<Box sx={{ height: "100%", width: '100%', background: "#fff" }}>
						<DataGrid
							headerHeight={80}
							rowHeight={80}
							sx={grigStyle}
							className={Style.data}
							rows={rows}
							columns={columns}
							components={{ NoRowsOverlay, NoResultsOverlay }}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[5, 10, 15]}
							checkboxSelection
							disableRowSelectionOnClick
						/>
					</Box>
				</div>
			</div>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						TransitionComponent: Fade,
					},
				}}
			>
				<Fade in={open}>
					<Box className={Style.modelBox}>
						<CloseIcon className={Style.closeBtn} onClick={handleClose} />
						<AddEditLocation handleClose={handleClose} id={editLocation ? editLocation.id : ""} />
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
