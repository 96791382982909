import Style from "./style.module.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { File } from "_assets/icons/icon";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { orderActions, alertActions, locationActions } from "_store";
import { useSpring, animated } from "@react-spring/web";
import { Loader } from "../../_components/loader";
// import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DeleteIcon from "@mui/icons-material/Delete";
import { ShopifyImport } from "./ShopifyImport";
import Papa from "papaparse";
import moment from "moment";
// import { Input } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import QRCode from "qrcode.react";
import QRCodee from "qrcode";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export { Orders };

//<Model Styling>
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
//<Model Styling/>

function Orders() {
  const dispatch = useDispatch();
  const mobile = useSelector((x) => x.mobile.value);
  const [rows, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");

  const [search, setSearch] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [status, setStatus] = useState("");
  const [pushStatus, setPushStatus] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [pickUp, setPickUp] = useState([]);

  const [startdate, setstartDate] = useState("");
  const [enddate, setendDate] = useState("");
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [dropDownHandle, setDropDownHandle] = useState(false);
  const [onfleetButton, setOnfleetButton] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [role, setRole] = useState("");
  const [sync, setSycn] = useState(false);
  const [manipulatedUserOrders, setManipulatedUserOrders] = useState(null);

  //MODEL HANDLE
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //MODEL HANDLE

  function changeStatus(status) {
    try {
      var STATUS = '';
      switch (status) {
        case 'pending': STATUS = "pending";
          break;
        case 'requested': STATUS = "requested";
          break;
        case 'pickedUp': STATUS = "picked Up";
          break;
        case 'onTheWay': STATUS = "on The Way";
          break;
        case 'delayed': STATUS = "delayed";
          break;
        case 'failed':
        case 'rejected':
        case 'cancelled': STATUS = "cancelled";
          break;
        case 'delivered': STATUS = "delivered";
          break;
      }
      return STATUS
    } catch (error) {
      return ""
    }
  }

  const userOrders = useSelector((x) => x.order.list);

  const updatedUserOrders = userOrders?.value?.data?.orders?.length > 0 ? userOrders.value.data.orders.map(order => {
    // Check if noOfItems is 0, then set it to 1
    if (order.noOfItems === 0) {
      return {
        ...order,
        noOfItems: 1
      }
    }
    // If noOfItems is not 0, leave it unchanged
    return order;
  }) : [];

  useEffect(() => {
    if (manipulatedUserOrders?.loading || !manipulatedUserOrders || refetch) {
      if (userOrders?.value?.data?.orders?.length > 0) {
        setManipulatedUserOrders({
          ...userOrders,
          value: {
            ...userOrders.value,
            data: {
              ...userOrders.value.data,
              orders: updatedUserOrders
            }
          }
        })
      } else {
        setManipulatedUserOrders(userOrders)
      }
      setRefetch(false)
    }
  }, [userOrders, refetch])


  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, [userOrders]);

  useEffect(() => {
    try {
      dispatch(locationActions.getAll()).then((data) => {
        setPickUp(data?.payload?.data?.locations.length != 0 ? data?.payload?.data?.locations : [])
      });
    } catch (error) {
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("import")) {
      setSycn(true);
    } else {
      setSycn(false);
    }

  }, [sync]);

  useEffect(() => {
    var search_by = "";
    if (search.includes("M-") || search.includes("m-")) {
      search_by = "manual_order";
    } else {
      const regMatch = /^[a-zA-Z]*$/.test(search);
      if (regMatch) {
        search_by = "merchant";
      } else {
        search_by = "order"
      }
    }
    let searchObj = {
      searchCustomer: searchCustomer,
      search: search,
      search_by: search_by,
      status: status,
      optimoPushedStatus: pushStatus,
      date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
    };
    dispatch(orderActions.getAll(searchObj)).unwrap();
    setRefetch(true);
    // console.log("searchObj", searchObj);
  }, [search, searchCustomer, status, startdate, enddate, pushStatus, trigger]);

  useEffect(() => {
    const render = manipulatedUserOrders?.value?.data;
    if (render?.orders?.length > 0) {
      const allOrder = render?.orders?.map((x) => {
        var manualOrder = "";
        if (x.orderNo == "") {
          if (x.manualOrderNo) {
            switch (String(x.manualOrderNo).length) {
              case 1: manualOrder = `M-000${x.manualOrderNo}`;
                break;
              case 2: manualOrder = `M-00${x.manualOrderNo}`;
                break;
              case 3: manualOrder = `M-0${x.manualOrderNo}`;
                break;
              case 4: manualOrder = `M-${x.manualOrderNo}`;
                break;
            }
          } else {
            manualOrder = "M"
          }
        }

        return {
          id: x._id,
          status: changeStatus(x.status),
          // amount: (x.amount == null ? `$0` : `$${String(x.amount)}`),
          orderID: (x.orderNo == "" ? manualOrder : x.orderNo),
          customer: x.shipping_address?.name ? x.shipping_address?.name : "---",
          businessName: x.user?.businessName ? x.user?.businessName : "",
          noOfItems: (x.noOfItems == null ? `0 boxes` : `${String(x.noOfItems)} boxes`),
          optimoPushedStatus: x.optimoPushedStatus
            ? x.optimoPushedStatus
            : "pending",
          date: moment(x.shopifyOrderDate).format("YYYY-MM-DD HH:mm"),
        };
      });
      setRow(allOrder);
    } else {
      setRow([]);
    }
  }, [userOrders, search, searchCustomer, status, startdate, enddate, pushStatus, manipulatedUserOrders]);

  useEffect(() => {
    if (
      (search && search !== "") ||
      (status && status !== "") ||
      (searchCustomer && searchCustomer !== "") ||
      (pushStatus && pushStatus !== "") ||
      (startdate && startdate !== "") ||
      (enddate && enddate !== "")
    ) {
      const filter = {
        searchCustomer: searchCustomer,
        search: search,
        status: status,
        optimoPushedStatus: pushStatus,
        startdate: startdate,
        enddate: enddate,
      };
      localStorage.setItem("filter", JSON.stringify(filter));
    }
  }, [search, searchCustomer, status, startdate, enddate, pushStatus]);

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem("filter"));
    if (filter) {
      // console.log("filter", filter);
      setSearch(filter.search);
      setSearchCustomer(filter.searchCustomer);
      setStatus(filter.status);
      setstartDate(filter.startdate);
      setendDate(filter.enddate);
      setPushStatus(filter.optimoPushedStatus);
    }
  }, []);

  const changeHandler = async (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    setFileName(event.target.files[0].name);
    const formData = new FormData();
    formData.append("csv", event.target.files[0]);
    Papa.parse(event.target.files[0], {
    });

    try {
      let orderFetch = await dispatch(
        orderActions.importOrder(formData)
      ).unwrap();
      if (orderFetch.status === 200) {
        // redirect to login page and display success alert
        const message = orderFetch?.data?.data;
        handleClose(false);
        dispatch(alertActions.success({ message, showAfterRedirect: true }));
      }
      // message = 'Order has been import successfully';
      // dispatch(alertActions.success({ message, showAfterRedirect: true }));

      var search_by = "";
      if (search.includes("M-") || search.includes("m-")) {
        search_by = "manual_order";
      } else {
        const regMatch = /^[a-zA-Z]*$/.test(search);
        if (regMatch) {
          search_by = "merchant";
        } else {
          search_by = "order"
        }
      }
      let searchObj = {
        searchCustomer: searchCustomer,
        search: search,
        search_by: search_by,
        status: status,
        date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
      };
      dispatch(orderActions.getAll(searchObj));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearch("");
    setSearchCustomer("");
    setStatus("");
    setendDate("");
    setstartDate("");
    setPushStatus("");
    localStorage.removeItem("filter");
  };

  // // Grid Style
  const grigStyle = {
    ".MuiDataGrid-overlayWrapperInner": {
      maxHeight: "500px",
      minHeight: "500px",
    },
    ".MuiCheckbox-root": {
      padding: "0px",
    },
    ".MuiDataGrid-overlayWrapper": {
      height: "100%!important",
    },
    ".MuiDataGrid-columnHeaders": {
      background: "#f7f7f7",
      minHeight: "80px!important",
    },
    ".MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
      padding: "0 0 0 25px",
    },
    ".MuiDataGrid-cellContent": {
      fontFamily: "AxiformaRegular",
      fontSize: "14px",
      color: "#303030",
    },
    ".MuiDataGrid-columnHeaderTitleContainerContent": {
      fontFamily: "AxiformaSemiBold",
      color: "#000",
      fontSize: "14px",
    },
  };

  // //GRID COLUMN
  var columns = [];
  if (role == "admin") {
    columns = [
      {
        field: "id",
        headerName: "ID",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "orderID",
        headerName: "Order No.",
        minWidth: 80,
        maxWidth: 100,
        flex: 1,
        filterable: true,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 120,
        renderCell: (params) => (
          <span className={`${params.value.replaceAll(" ", "")} status`}>
            {params.value}
          </span>
        ),
        flex: 1,
        filterable: true,
      },
      // {
      //   field: "amount",
      //   headerName: "Amount",
      //   minWidth: 80,
      //   flex: 1,
      //   filterable: true,
      // },
      {
        field: "businessName",
        headerName: "Business Name",
        minWidth: 150,
        maxWidth: 200,
        flex: 1,
        filterable: true,
      },
      {
        field: "customer",
        headerName: "Customer",
        minWidth: 180,
        flex: 1,
        filterable: true,
      },
      {
        field: "optimoPushedStatus",
        headerName: "Pickup Request",
        minWidth: 150,
        flex: 1,
        filterable: true,
      },
      {
        field: "noOfItems",
        headerName: "Quantity",
        minWidth: 150,
        flex: 1,
        filterable: true,
        renderCell: (cellValues) => {
          return (
            <BoxesComponent
              cellValues={cellValues}
              trigger={trigger}
              setTrigger={setTrigger}
            />
          );
        },
      },
      {
        field: "date",
        headerName: "Tracking",
        minWidth: 150,
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Link
              to={`https://iships.ca?orderId=${cellValues.row.id}`}
              className={Style.col_btn}
              target="_blank"
            >
              Track
            </Link>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        renderCell: (cellValues) => {
          // return <Link onClick={() => { setEditOrder(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit</Link>;
          return (
            <Link to={`/edit/${cellValues.row.id}`} className={Style.col_btn}>
              Edit
            </Link>
          );
        },
        minWidth: 150,
        filterable: false,
        flex: 1,
      },
    ];
  } else {
    columns = [
      {
        field: "id",
        headerName: "ID",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "orderID",
        headerName: "Order No.",
        minWidth: 80,
        maxWidth: 100,
        flex: 1,
        filterable: true,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 120,
        renderCell: (params) => (
          <span className={`${params.value.replaceAll(" ", "")} status`}>
            {params.value}
          </span>
        ),
        flex: 1,
        filterable: true,
      },
      // {
      //   field: "amount",
      //   headerName: "Amount",
      //   minWidth: 80,
      //   flex: 1,
      //   filterable: true,
      // },
      {
        field: "customer",
        headerName: "Customer",
        minWidth: 180,
        flex: 1,
        filterable: true,
      },
      {
        field: "optimoPushedStatus",
        headerName: "Pickup Request",
        minWidth: 150,
        flex: 1,
        filterable: true,
      },
      {
        field: "noOfItems",
        headerName: "Quantity",
        minWidth: 150,
        flex: 1,
        filterable: true,
        renderCell: (cellValues) => {
          return (
            <BoxesComponent
              cellValues={cellValues}
              trigger={trigger}
              setTrigger={setTrigger}
            />
          );
        },
      },
      {
        field: "date",
        headerName: "Tracking",
        minWidth: 150,
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Link
              to={`https://iships.ca?orderId=${cellValues.row.id}`}
              className={Style.col_btn}
              target="_blank"
            >
              Track
            </Link>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        renderCell: (cellValues) => {
          // return <Link onClick={() => { setEditOrder(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit</Link>;
          return (
            <Link to={`/edit/${cellValues.row.id}`} className={Style.col_btn}>
              Edit
            </Link>
          );
        },
        minWidth: 150,
        filterable: false,
        flex: 1,
      },
    ];
  }

  function NoRowsOverlay() {
    return (
      <div className={Style.container_Notfound}>
        <div className={Style.notFound}></div>
      </div>
    );
  }

  function NoResultsOverlay() {
    return (
      <div className={Style.container_Notfound}>
        <div className={Style.notFound}></div>
      </div>
    );
  }

  const pushOptimo = async () => {
    let obj = {
      arrayOfOrderIds: selectedIds,
    };
    try {
      let message;
      const response = await dispatch(orderActions.pushOptimoMultiple(obj)).unwrap();
      if (response.success && response.data && response.data.data.success == true) {
        message = "Order has been pushed to optimo";
        dispatch(alertActions.success({ message, showAfterRedirect: true }));
      } else {
        message = response.data.data.message;
        dispatch(alertActions.error(message));
      }
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  const deleteOrders = async () => {
    let obj = {
      orderIds: selectedIds,
    };
    // console.log("selectedIds", obj);
    // dispatch(orderActions.getAll()).unwrap()
    try {
      let message;
      await dispatch(orderActions.deleteorder(obj)).unwrap();
      message = "Orders deleted successfully";
      dispatch(alertActions.success({ message, showAfterRedirect: true }));
      setSelectedIds([]);

      var search_by = "";
      if (search.includes("M-") || search.includes("m-")) {
        search_by = "manual_order";
      } else {
        const regMatch = /^[a-zA-Z]*$/.test(search);
        if (regMatch) {
          search_by = "merchant";
        } else {
          search_by = "order"
        }
      }

      let searchObj = {
        searchCustomer: searchCustomer,
        search: search,
        status: status,
        search_by: search_by,
        date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
      };
      dispatch(orderActions.getAll(searchObj)).unwrap();
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  const [massRequestButton, setMassRequestButton] = useState(false);

  useEffect(() => {
    if (selectedIds.length > 0) {
      const selectedOrders1 = rows.filter((x) => selectedIds.includes(x.id));
      const index = selectedOrders1.findIndex(
        (x) => x.optimoPushedStatus !== "requested"
      );

      // if any selected order's onfleetpushstatus is not pending then set mass button false else true
      const index2 = selectedOrders1.findIndex(
        (x) => x.optimoPushedStatus !== "pending"
      );
      if (index2 !== -1) {
        setMassRequestButton(false);
      } else {
        setMassRequestButton(true);
      }

      if (index !== -1) {
        setOnfleetButton(true);
      } else {
        setOnfleetButton(false);
      }

      const selectedOrders2 = manipulatedUserOrders?.value?.data?.orders?.filter((x) =>
        selectedIds.includes(x._id)
      );
      setSelectedOrders(selectedOrders2);
    } else {
      setOnfleetButton(false);
      setMassRequestButton(false);
    }
  }, [selectedIds]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  async function generateCode(id) {
    try {
      // const qrCodeDataURL = await QRCodee.toDataURL("https://www.iships.ca/?orderId=" + id);
      const qrCodeDataURL = await QRCodee.toDataURL(id);
      return qrCodeDataURL
    } catch (error) {
      console.log('qr error', error);
      return ""
    }
  };
  const firstActivePickup = pickUp?.find(item => item.status === 'Active');

  const generateLabels = async () => {

    var notPickupLocation = 0;
    for (let x = 0; x < selectedOrders.length; x++) {
      notPickupLocation = ((selectedOrders[x] && selectedOrders[x].pickup && selectedOrders[x].pickup != "") || firstActivePickup ? notPickupLocation : notPickupLocation + 1)
    }
    if (notPickupLocation == 0) {
      let message = "Pickup request sent successfully";
      dispatch(alertActions.success({ message, showAfterRedirect: true }));
      const pdfWidth = 4;
      const pdfHeight = 6;
      const pdf = new jsPDF({
        unit: "in",
        format: [pdfWidth, pdfHeight],
        orientation: "portrait"
      });
      var newSelectedOrders = [];
      selectedOrders.map(async (ele, indd) => {
        newSelectedOrders.push()
        await generateCode(ele._id).then((data) => {
          var obj = ele;
          newSelectedOrders.push({ ...obj, qrURL__: data });
        })
      })
      setTimeout(() => {
        newSelectedOrders.map((val, ind) => {
          var pickUpData = pickUp.filter((ele) => ele.name == (val.pickup ? val.pickup : ""));
          if (pickUpData.length == 0) {
            pickUpData = [firstActivePickup]
          }
          dispatch(orderActions.update({ orderID: val?._id, data: { ...val, status: "requested" } })).unwrap();
          //change status in the manipulated user orders
          // const manipulatedUserOrdersCopy = {...manipulatedUserOrders}
          const manipulatedUserOrdersCopy = manipulatedUserOrders?.value?.data?.orders.map((order) => {
            if (order._id == val._id) {
              return {
                ...order,
                status: "requested"
              }
            }
            return order;
          });
          setManipulatedUserOrders({
            value: {
              ...manipulatedUserOrders.value,
              data: {
                ...manipulatedUserOrders.value.data,
                orders: manipulatedUserOrdersCopy
              }
            }
          });

          var totalPage = 0;
          var pickUpLineBreak = pickUpData && pickUpData.length != 0 ? ((Number(pickUpData[0].address.length) + Number(pickUpData[0].city.length)) >= 36 ? true : false) : false;
          var lineBreak = val?.shipping_address?.address1 && val?.shipping_address?.address1 != "undefined" && (Number(val?.shipping_address?.address1.length) + Number(val?.shipping_address?.address2 != undefined && val?.shipping_address?.address2 != "" && val?.shipping_address?.address2 != null ? val?.shipping_address?.address2.length : 0)) >= 25 ? true : false;
          totalPage = (val?.noOfItems == null ? 1 : val?.noOfItems);
          for (let i = 0; i < totalPage; i++) {
            const from = val?.user?.businessLocation || val?.user?.businessAddress;
            pdf.setFontSize(12);
            pdf.setFont("Roboto", "bold");
            const fromText = pdf.splitTextToSize(`${from}`, 1.5);
            var manualOrder = "";
            if (val.orderNo == "") {
              if (val.manualOrderNo) {
                switch (String(val.manualOrderNo).length) {
                  case 1: manualOrder = `M-000${val.manualOrderNo}`;
                    break;
                  case 2: manualOrder = `M-00${val.manualOrderNo}`;
                    break;
                  case 3: manualOrder = `M-0${val.manualOrderNo}`;
                    break;
                  case 4: manualOrder = `M-${val.manualOrderNo}`;
                    break;
                }
              } else {
                manualOrder = "M"
              }
            }
            pdf.setLineWidth(0.01);
            pdf.line(0, 0.90, 4, 0.90); // horizontal line    
            pdf.text(`#Order: ${val.orderNo == "" ? manualOrder : val.orderNo}`, 2.00, 0.65, 'center')
            pdf.setFontSize(9);
            pdf.setFont("Roboto", "bold");
            pdf.text(`Shipper `, 0.30, 1.20);
            pdf.setFont("Roboto", "");
            pdf.text(`${pickUpData && pickUpData.length != 0 && pickUpData[0].name ? pickUpData[0].name : ""}`, 0.30, 1.40);
            { pickUpLineBreak == false ? pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].address + ", " + pickUpData[0].city : ""}`, 0.30, 1.60) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].address + ", " : ""}`, 0.30, 1.60) }
            { pickUpLineBreak == false ? pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].state + ", " + pickUpData[0].zipCode : ""}`, 0.30, 1.80) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].city : ""}`, 0.30, 1.80) }
            { pickUpLineBreak == false ? pdf.text(``, 0.30, 2.00) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].state + ", " + pickUpData[0].zipCode : ""}`, 0.30, 2.00) }
            pdf.setFontSize(13);
            pdf.setFont("Roboto", "");
            // TODO
            // pdf.text(`${ind + 1}/${i + 1}`, 2.65, 0.85);
            pdf.text(`${i + 1}/${totalPage}`, 3.40, 0.65);
            { val?.id_card || val?.insurance ? pdf.addImage(val.qrURL__, 'JPEG', 1.15, 3.85, 1.70, 1.70) : pdf.addImage(val.qrURL__, 'JPEG', 1.15, 3.65, 1.70, 1.70) }
            pdf.setFontSize(10);
            pdf.setFont("Roboto", "bold");
            pdf.text(`Receiver`, 0.30, 2.40);
            pdf.setFontSize(9);
            pdf.setFont("Roboto", "bold");
            pdf.text(`Name: `, 0.30, 2.60)
            { lineBreak == false ? pdf.text(`Address: `, 0.30, 2.80) : pdf.text(`Address: `, 0.30, 2.80) }
            { lineBreak == false ? pdf.text(`Contact: `, 0.30, 3.00) : pdf.text(`Contact: `, 0.30, 3.20) }
            if (val?.id_card || val?.insurance || val?.signature) {
              lineBreak == false ? pdf.text(`Instructions: `, 0.30, 3.20) : pdf.text(`Instructions: `, 0.30, 3.40)
            }
            pdf.setFont("Roboto", "");

            { lineBreak == false ? pdf.text(`${(val?.shipping_address?.address1 && val?.shipping_address?.address1 == "undefined" ? "" : val?.shipping_address?.address1 + (val?.shipping_address && val?.shipping_address?.address2 != null && val?.shipping_address?.address2 != "" && val?.shipping_address?.address2 != undefined ? " | " + val?.shipping_address?.address2 : "")) + (val?.shipping_address.city ? ", " + val?.shipping_address.city : "") + (val?.shipping_address.province ? ", " + val?.shipping_address.province : "") + (val?.shipping_address.zip ? ", " + val?.shipping_address.zip : "")}`, 0.82, 2.80) : pdf.text(`${(val?.shipping_address?.address1 && val?.shipping_address?.address1 == "undefined" ? "" : val?.shipping_address?.address1 + (val?.shipping_address && val?.shipping_address?.address2 != null && val?.shipping_address?.address2 != "" && val?.shipping_address?.address2 != undefined ? " | " + val?.shipping_address?.address2 : "")) + ","}`, 0.82, 3.00) }

            { lineBreak == false ? pdf.text(`${val?.shipping_address?.name != "undefined" ? val?.shipping_address?.name : (val?.customer?.first_name ? val?.customer?.first_name : "") + " " + (val?.customer?.last_name ? val?.customer?.last_name : "")}`, 0.74, 2.60) : pdf.text(`${(val?.shipping_address.city ? val?.shipping_address.city : "") + (val?.shipping_address.province ? ", " + val?.shipping_address.province : "") + (val?.shipping_address.zip ? ", " + val?.shipping_address.zip : "")}`, 0.82, 2.80) }

            { lineBreak == false ? pdf.text(`${val?.shipping_address.phone != "" ? val?.shipping_address.phone : ""}`, 0.82, 3.00) : pdf.text(`${val?.shipping_address?.name != "undefined" ? val?.shipping_address?.name : (val?.customer?.first_name ? val?.customer?.first_name : "") + " " + (val?.customer?.last_name ? val?.customer?.last_name : "")}`, 0.74, 2.60) }

            { lineBreak == false ? pdf.text(``, 0.82, 3.00) : pdf.text(`${val?.shipping_address.phone != "" ? val?.shipping_address.phone : ""}`, 0.82, 3.20) }

            pdf.setLineWidth(0.01);
            { val?.id_card || val?.insurance || val?.signature ? lineBreak == false ? pdf.line(0, 3.65, 4, 3.65) : pdf.line(0, 3.85, 4, 3.85) : lineBreak == false ? pdf.line(0, 3.40, 4, 3.40) : pdf.line(0, 3.60, 4, 3.60) } // horizontal line 

            { lineBreak == false ? pdf.text(`${val.id_card ? "ID is required" : ""}${val.signature ? (val.id_card ? ", Signature is required" : "Signature is required") : ""}${val.insurance ? (val.id_card || val.signature ? ", Insurance is required" : "Insurance is required") : ""}`, 0.30, 3.40) : pdf.text(`${val.id_card ? "ID is required" : ""}${val.signature ? (val.id_card ? ", Signature is required" : "Signature is required") : ""}`, 0.30, 3.60) }

            // {  lineBreak == false ?  pdf.text(`${val.insurance ? (val.id_card || val.signature ? "Insurance is required" : "Insurance is required") : ""}`, 1.05, val.id_card ? 3.40 : val.signature ? 3.40 : 3.20): pdf.text(`${val.insurance ? (val.id_card || val.signature ? "Insurance is required" : "Insurance is required") : ""}`, 1.05, val.id_card ? 3.60 : val.signature ? 3.80 : 3.40) }

            pdf.addImage('/images/logoLight.png', 'JPEG', 0.30, 0.40, 0.60, 0.35);
            if (i != (newSelectedOrders.length - 1 == ind ? totalPage - 1 : totalPage)) {
              pdf.addPage();
            }
          }
        });
        pdf.save(`Label.pdf`);

        var search_by = "";
        if (search.includes("M-") || search.includes("m-")) {
          search_by = "manual_order";
        } else {
          const regMatch = /^[a-zA-Z]*$/.test(search);
          if (regMatch) {
            search_by = "merchant";
          } else {
            search_by = "order"
          }
        }

        let searchObj =
        {
          "searchCustomer": "",
          "search": '',
          "search_by": '',
          "status": '',
          "date": ''
        }
        dispatch(orderActions.getAll(searchObj));
        return null;
      }, 2000);

    } else {
      let message = "Please add pickup location.";
      dispatch(alertActions.error({ message, showAfterRedirect: true }));
      return null;
    }
  };

  const massRequestPickup = async () => {
    let noPhoneOrders = [];
    selectedOrders.forEach((val, ind) => {
      if (!val?.shipping_address?.phone) {
        noPhoneOrders.push(val.orderNo);
      }
    });
    if (noPhoneOrders.length > 0) {
      let message =
        "Please add customer phone number for " + noPhoneOrders.join(", ");

      dispatch(alertActions.error({ message, showAfterRedirect: true }));
      return false;
    } else {
      const res = await dispatch(
        orderActions.requestToShip({
          orderIdsArray: selectedIds,
        })
      );
      if (res?.payload?.success) {
        setSelectedIds([]);

        var search_by = "";
        if (search.includes("M-") || search.includes("m-")) {
          search_by = "manual_order";
        } else {
          const regMatch = /^[a-zA-Z]*$/.test(search);
          if (regMatch) {
            search_by = "merchant";
          } else {
            search_by = "order"
          }
        }

        let searchObj = {
          searchCustomer: searchCustomer,
          search: search,
          search_by: search_by,
          status: status,
          date:
            startdate && enddate ? [moment(startdate), moment(enddate)] : [],
        };
        dispatch(orderActions.getAll(searchObj)).unwrap();
        return true;
      }
    }
  };

  async function syncOrders() {
    try {
      var importDetail = localStorage.getItem('import');
      if (importDetail) {
        importDetail = JSON.parse(importDetail);
      }
      if (importDetail && importDetail.shopifyAccessToken && importDetail.date && importDetail.linkk) {
        const resp = await dispatch(orderActions.syncOrder({
          store_url: importDetail.linkk,
          api_version: importDetail.date,
          accessToken: importDetail.shopifyAccessToken
        }));
        if (resp?.payload?.success) {
          let message = "Sync Start";
          dispatch(alertActions.success({ message, showAfterRedirect: true }));
          setTimeout(() => {
            window.location.reload()
          }, 3000);
          return true;
        }
      } else {
        let message = "Please fill details.";
        dispatch(alertActions.error({ message, showAfterRedirect: true }));
        return false;
      }
    } catch (error) {
      // console.log('error', error);
      return false;
    }

  }

  return (
    <div
      className={Style.order_mainContainer}
      style={{ marginLeft: mobile ? mobile.class : "290px" }}
    >
      <div className={Style.main_container}>
        <div className={Style.filters_wrapper}>
          <h6 className={Style.filter_title}>Filters</h6>
          <hr style={{ borderColor: "#dcdcdc", opacity: 0.3 }} />
          <div className={Style.search_form}>
            <form className={Style.form_filter} onSubmit={handleSubmit}>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Search</label>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  className={Style.filter_input}
                  type="text"
                  placeholder={role === "admin" ? "Order No, Merchant" : "Order No, Customer"}
                />
              </div>
              <div className={Style.filter_inputWrapper} style={role === "admin" ? { display: "block" } : { display: "none" }}>
                <label className={Style.filter_label}>Search Customer</label>
                <input
                  onChange={(e) => setSearchCustomer(e.target.value)}
                  value={searchCustomer}
                  name="searchCustomer"
                  className={Style.filter_input}
                  type="text"
                  placeholder="Customer"
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Requested</label>
                <select
                  onChange={(e) => setPushStatus(e.target.value)}
                  value={pushStatus}
                  name="status"
                  className={Style.order_status}
                  placeholder="Order Status"
                  style={{
                    background: "#fff",
                  }}
                >
                  <option value="Order Status">Request Pickup</option>
                  <option value="pending">Pending</option>
                  <option value="requested">Requested</option>
                  <option value="pushed">Pushed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                {/* <input onChange={(e) => setStatus(e.target.value)} value={status} name="status" className={Style.filter_input} type='text' placeholder='Order Status' /> */}
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Status</label>
                <select
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  name="status"
                  className={Style.order_status}
                  placeholder="Order Status"
                  style={{
                    background: "#fff",
                  }}
                >
                  <option value="Order Status">Order Status</option>
                  <option value="pending">Pending</option>
                  <option value="requested">Requested</option>
                  <option value="pickedUp">Picked up</option>
                  <option value="onTheWay">On the way</option>
                  <option value="delivered">Delivered</option>
                  <option value="delayed">Delayed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                {/* <input onChange={(e) => setStatus(e.target.value)} value={status} name="status" className={Style.filter_input} type='text' placeholder='Order Status' /> */}
              </div>

              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Start Date</label>
                <input
                  onChange={(e) => setstartDate(e.target.value)}
                  value={startdate}
                  name="startDate"
                  className={Style.filter_input}
                  type="date"
                  placeholder="Order No, Store, Merchant, etc."
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>End Date</label>
                <input
                  onChange={(e) => setendDate(e.target.value)}
                  value={enddate}
                  name="endDate"
                  className={Style.filter_input}
                  type="date"
                  placeholder="Order No, Store, Merchant, etc."
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <button type="submit" className={Style.filter_button}>
                  Reset Filter
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className={Style.content_wrapper}>
          <div className={Style.head}>
            <h1 className={Style.title}>Orders</h1>
            <div className={Style.actions}>
              {/* <div className={Style.add_search}>
                <div className={Style.search}>
                  <Input
                    sx={{ height: "100%", width: "100%", padding: "10px" }}
                    placeholder="Search by ID, Name"
                    onChange={(e) => setSearch(e.target.value)}
                    className={Style.search_field}
                  />

                  <div className={Style.serach_box}>
                    <SearchIcon className={Style.search_icons} />
                  </div>
                </div>
              </div> */}
              {selectedIds.length > 0 &&
                role === "admin" &&
                (onfleetButton ? (
                  <button
                    className={Style.download_btn}
                    disabled
                    style={{
                      cursor: "not-allowed",
                      backgroundColor: "#ccc",
                    }}
                  >
                    {/* <AllInclusiveIcon /> */}
                    Push Optimo
                  </button>
                ) : (
                  <button className={Style.download_btn} onClick={pushOptimo}>
                    {/* <AllInclusiveIcon /> */}
                    Push Optimo
                  </button>
                ))}
              {selectedIds.length > 0 && (
                <button
                  onClick={() => {
                    deleteOrders();
                  }}
                  className={Style.button_import}
                >
                  {" "}
                  <DeleteIcon />
                  Delete Order
                </button>
              )}
              {selectedIds.length > 0 && (
                <button
                  onClick={async () => {
                    const res = await massRequestPickup();
                    if (res) {
                      generateLabels();
                    }
                  }}
                  className={Style.button_import}
                >
                  {" "}
                  Generate Labels
                </button>
              )}
              {/* {selectedIds.length > 0 &&
                role !== "admin" &&
                massRequestButton && (
                  <button
                    onClick={() => {
                      massRequestPickup();
                    }}
                    className={Style.button_import}
                  >
                    {" "}
                    Request Pickup
                  </button>
                )} */}
              <button className={Style.button_import} onClick={syncOrders} style={sync ? { display: "block" } : { display: "none" }}>
                Sync Order
              </button>
              <Link to="/edit" className={Style.button_import}>
                <AddIcon />
                Create Order
              </Link>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <button
                  className={Style.button_import}
                  onClick={() => {
                    setDropDownHandle(!dropDownHandle);
                  }}
                >
                  <File /> Import&#x25BC; {/*&#x25B2;*/}
                </button>
                {dropDownHandle && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#f9f9f9",
                      minWidth: "180px",
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      zIndex: "1",
                      right: "0",
                      height: "auto",
                      top: "",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      onClick={() => {
                        handleOpen();
                      }}
                      style={{
                        width: "150px",
                        padding: "10px",
                        border: "none",
                        textAlign: "left",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        background: "none",
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc",
                        fontSize: "14px",
                        fontFamily: "AxiformaRegular",
                      }}
                    >
                      <File />
                      <p
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        Import Shopify
                      </p>
                    </button>
                    <div>
                      <label
                        htmlFor="raised-button-file"
                        style={{
                          width: "150px",
                          padding: "10px",
                          border: "none",
                          textAlign: "left",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          background: "none",
                          cursor: "pointer",
                          borderBottom: "1px solid #ccc",
                          fontSize: "14px",
                          fontFamily: "AxiformaRegular",
                        }}
                      >
                        <File />
                        <p
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Import CSV
                        </p>
                        <input
                          type="file"
                          name="file"
                          accept=".csv"
                          id="raised-button-file"
                          onChange={changeHandler}
                          style={{ display: "none", margin: "10px auto" }}
                        />
                      </label>
                      <span className={Style.fileName}>{fileName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {userOrders?.loading && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <Loader />
            </div>
          )}
          {!(userOrders?.loading || userOrders?.error) && (
            <div className={Style.orderList}>
              <Box
                sx={{
                  height: "100%",
                  maxWidth: "100%",
                  width: "100%",
                  position: "relative",
                  background: "#fff",
                }}
              >
                <DataGrid
                  headerHeight={80}
                  rowHeight={80}
                  sx={grigStyle}
                  className={Style.data}
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (newRowSelectionModel.length > 0) {
                      setSelectedIds(newRowSelectionModel);
                    } else {
                      setSelectedIds([]);
                    }
                  }}
                  // selectionModel={selectedIds}
                  components={{ NoRowsOverlay, NoResultsOverlay }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15]}
                // disableRowSelectionOnClick
                />
              </Box>
            </div>
          )}
        </div>
      </div>
      {selectedOrders?.length > 0 &&
        selectedOrders?.map((x, i) => (
          <div
            key={i}
            style={{
              display: "none",
            }}
          >
            <QRCode
              id={"myqr" + i}
              value={JSON.stringify({
                name: x.shipping_address?.name,
                address:
                  x.shipping_address?.address1 ||
                  `${x.shipping_address.city}, ${x.shipping_address.province}, ${x.shipping_address.country}, ${x.shipping_address.zip}`,
              })}
              size={250}
              style={{ margin: "auto", display: "block" }}
            />
          </div>
        ))}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box className={Style.modelBox}>
            <CloseIcon className={Style.closeBtn} onClick={handleClose} />
            {/* {importshopify ? <ShopifyImport handleClose={handleClose} /> : null
                            <EditOrder handleClose={handleClose} id={editorder ? editorder.id : ""} />
                            } */}
            <ShopifyImport handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const BoxesComponent = ({ cellValues, trigger, setTrigger }) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(cellValues.row.noOfItems.split(" ")[0]);
  const dispatch = useDispatch();

  return (
    <>
      {show ? (
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setShow(false);
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              setShow(false);
              if (Number(value) !== Number(cellValues.row.noOfItems.split(" ")[0])) {
                const res = await dispatch(
                  orderActions.updateNoOfItems({
                    id: cellValues.row.id,
                    noOfItems: value,
                  })
                ).unwrap();

                // console.log("res", res);
                setTrigger(!trigger);
              }
            }
          }}
          style={{
            width: "80px",
            height: "40px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "5px",
          }}
        />
      ) : (
        <p onClick={() => setShow(true)}>{cellValues.row.noOfItems}</p>
      )}
    </>
  );
};
