import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import { authActions } from '_store';
import { fetchWrapper } from "_helpers";
import { alertActions } from "_store";
// create slice

const name = "order";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const orderActions = { ...slice.actions, ...extraActions };
export const orderReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    list: null,
    item: null,
  };
}

function createExtraActions() {
  // const baseUrl = `${process.env.FRONTEND_URL_PORT}/users`;
  // const baseUrl = `${process.env.BACKEND_URL}`;

  return {
    importOrder: importOrder(),
    getAll: getAll(),
    update: update(),
    createSingle: createSingle(),
    pushOptimoMultiple: pushOptimoMultiple(),
    pushOptimo: pushOptimo(),
    importShopify: importShopify(),
    deleteorder: deleteorder(),
    requestToShip: requestToShip(),
    updateNoOfItems: updateNoOfItems(),
    syncOrder: syncOrder()
  };

  function importOrder() {
    return createAsyncThunk(
      `${name}/importOrder`,
      async (formData, { dispatch }) => {
        dispatch(alertActions.clear());
        // try {
        //     console.log("data before fetch", data);
        //     let dataOrder = await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/order/create`, { ordersArray: data })
        //     let message = dataOrder?.data?.message
        //     console.log("dataOrder", dataOrder);
        //     dispatch(alertActions.success({ message, showAfterRedirect: true }));
        // } catch (error) {
        //     dispatch(alertActions.error(error));
        // }
        const auth = JSON.parse(localStorage.getItem("auth"));
        const token = auth?.data?.token;
        return axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/order/create`, formData, {
            headers: {
              Accept: "application/json",
              Authorization: `${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
            let message = error.response.data.message;
            dispatch(alertActions.error(message));
            return error;
          });
      }
    );
  }

  function syncOrder() {
    return createAsyncThunk(`${name}/sync-order`, async (data) => {
      return  await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/order/sync-order`, data);
    }); 
  }

  function getAll() {
    return createAsyncThunk(`${name}/getAll`, async (data) => {
      return await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/order`, data);
    });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async function ({ orderID, data }) {
        await fetchWrapper.put(
          `${process.env.REACT_APP_BACKEND_URL}/order/${orderID}`,
          data
        );
      }
    );
  }
  function createSingle() {
    return createAsyncThunk(`${name}/createSingle`, async function ({ data }) {
      await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/create-single`,
        data
      );
    });
  }
  function deleteorder() {
    return createAsyncThunk(`${name}/deleteOrder`, async function (obj) {
      console.log(obj);
      await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/delete-orders`,
        obj
      );
    });
  }
  function importShopify() {
    return createAsyncThunk(`${name}/importShopify`, async (data) => {
      await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/import-shopify`,
        data
      );
    });
  }
  function pushOptimo() {
    return createAsyncThunk(`${name}/pushOptimo`, async (id) => {
      console.log(id);
      await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/push-optimo/${id}`
      );
    });
  }
  function pushOptimoMultiple() {
    return createAsyncThunk(`${name}/pushOptimoMultiple`, async (obj) => {
      console.log(obj);
      return await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/push-optimo`,
        obj
      );
    });
  }

  function requestToShip() {
    return createAsyncThunk(`${name}/requestToShip`, async (obj) => {
      const res = await fetchWrapper.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/request-to-push-optimo`,
        obj
      );
      return res;
    });
  }

  function updateNoOfItems() {
    return createAsyncThunk(`${name}/updateNoOfItems`, async (obj) => {
      const res = await fetchWrapper.put(
        `${process.env.REACT_APP_BACKEND_URL}/order/update-items/${obj.id}`,
        obj
      );
      return res;
    });
  }

}

function createExtraReducers() {
  return (builder) => {
    getAll();

    function getAll() {
      var { pending, fulfilled, rejected } = extraActions.getAll;
      builder
        .addCase(pending, (state) => {
          state.list = { loading: true };
        })
        .addCase(fulfilled, (state, action) => {
          state.list = { value: action.payload };
        })
        .addCase(rejected, (state, action) => {
          state.list = { error: action.error };
        });
    }
  };
}
