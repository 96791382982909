import * as React from 'react';
import { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { history } from '_helpers';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useSpring, animated } from '@react-spring/web';
import { AddEdit } from './AddEdit';
import { userActions, alertActions } from '_store';
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '@mui/material/Input';

export { List };
//<Model Styling>
const Fade = React.forwardRef(function Fade(props, ref) {
	const {
		children,
		in: open,
		onClick,
		onEnter,
		onExited,
		ownerState,
		...other
	} = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null, true);
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

Fade.propTypes = {
	children: PropTypes.element.isRequired,
	in: PropTypes.bool,
	onClick: PropTypes.any,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
	ownerState: PropTypes.any,
};
//<Model Styling/>

function List() {
	const history = useNavigate();

	const [selectedIds, setSelectedIds] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [rows, setRows] = React.useState([]);
    const [modifiedphone, setmodifiedphone] = useState("")
    const [lastphone, setLastPhone] = useState();
	const [editUser, setEditUser] = React.useState('');
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const mobile = useSelector(x => x.mobile.value)
	//MERCHANTS SEARCH FILTER
	const [searchValue, setSeatchValue] = React.useState('');
	const [search, setSearch] = React.useState(searchValue);

	const handleChange = (event) => {
		setSeatchValue(event.target.value);
	};
	const handleClick = () => {
		setSearch(searchValue);
	};

	const users = useSelector(x => x.users.list);
	// console.log("users====> ", users);

	const dispatch = useDispatch();
	// console.log("users all", useSelector(x => x.users.list));

	useEffect(() => {
		dispatch(userActions.getAll());
	}, []);

	//SET GRID ROW
	useEffect(() => {

		const data = users?.value?.data?.users
		// console.log("data", data);
		// console.log("search", search);
		if (search) {
			const filterData = data?.filter(x => {
				const filteredObj = x.name.includes(search) || x._id.toLowerCase().includes(search);
				return filteredObj;
			});
			console.log("filterData", filterData);
			if (users?.value?.data?.users.length > 0) {
				const userData = filterData.map(data => {
					return { "id": data._id, "businessName": data.businessName, "email": data.email, "phone": convertNumber(data.phoneNo.toString()), url: `edit/${data.id}` }
				})
				setRows(userData)
			}
		}
		else {
			// console.log("search", data);
			if (data?.length > 0) {
				const userData = data?.map(data => {
					return { "id": data._id, "businessName": data.businessName, "email": data.email, "phone": convertNumber(data.phoneNo.toString()), url: `edit/${data.id}` }
				})
				setRows(userData)
			}
		}

	}, [search, users]);

	const createMerchantOrder = async (data) => {

		const queryParams = new URLSearchParams();
		queryParams.set('merchant', data.id);
		const queryString = queryParams.toString();
		// console.log(queryString);
		history(`/edit?${queryString}`);
	}

	function convertNumber(data) {
        var numericString = data.replace(/[^\d]/g, '');
        if (numericString.length > 11) {
            return numericString;
        }
        if (numericString && numericString.length) {
        }
        if (modifiedphone && modifiedphone.length) {
        }
        if (lastphone && lastphone.length) {
        }
        if (modifiedphone && data.length < modifiedphone.length) {
            if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-") {
                return `${data.slice(0, data.length - 1)}`;
            }
            else {
                return `${data.slice(0, data.length)}`;
            }
        }
        else {
            if (numericString.charAt(0) == '1') {
                numericString = numericString.slice(1);
            }
            if (numericString.length >= 2) {
                return `+1 (${numericString.slice(0, 3)}) ${numericString.slice(3, 6)}-${numericString.slice(6)}`;
            }
            if (numericString.length >= 2) {
                if (numericString.charAt(0)) {
                    numericString = numericString.slice(1);
                }
                var format2 = ''
                var format3 = ''
                if (numericString.length > 3) {
                    format2 = numericString.slice(3, 6)
                    format3 = numericString.slice(6)
                }

                if (lastphone && lastphone.length) {
                }
                if (modifiedphone && modifiedphone.length) {
                }
                if (modifiedphone && modifiedphone.length && lastphone && lastphone.length && lastphone.length <= modifiedphone.length) {
                    return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
                }
                else {
                    if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == " " || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == ")" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "(") {
                        return `${data.slice(0, data.length - 1)}`;
                    }
                    else {
                        return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
                    }
                }
            }
        }
    }


	//GRID COLUMN
	const columns = [
		{
			field: 'id', headerName: 'Merchant ID #',
			minWidth: 150,
			maxWidth: 300,
			flex: 2,
		},
		{
			field: 'businessName',
			headerName: 'Business Name',
			minWidth: 120,
			flex: .8
		},
		{
			field: 'email',
			headerName: 'Merchant Email',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'phone',
			headerName: 'Merchant Phone #',
			type: 'number',
			minWidth: 150,
			flex: 1,
			align: 'left',
			headerAlign: 'left'
		},
		{
			field: "Edit Merchant",
			renderCell: (cellValues) => {

				return <Link onClick={() => { setEditUser(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit details</Link>;
			},
			minWidth: 150,
			filterable: false,
			flex: 1
		},
		{
			field: "Create Order",
			renderCell: (cellValues) => {

				// return <Link to={`/edit/${cellValues.row.id}`}  className={Style.col_btn}>Create Order</Link>;
				return <button onClick={() => { createMerchantOrder(cellValues.row) }} className={Style.col_btn}>Create Order</button>;
			},
			minWidth: 150,
			filterable: false
		}
	];

	// Grid Style
	const grigStyle =
	{
		'.MuiDataGrid-columnSeparator': {
			display: 'none',
		},
		".MuiDataGrid-overlayWrapperInner": {
			maxHeight: "500px",
			minHeight: "500px"
		},
		'.MuiDataGrid-overlayWrapper': {
			height: "100%!important"
		},
		'&.MuiDataGrid-root': {
			border: '',
		},
		'.MuiDataGrid-cellContent': {
			fontFamily: "AxiformaRegular",
			fontSize: "14px",
			color: '#303030'
		},
		'.MuiDataGrid-columnHeaderTitleContainerContent': {
			fontFamily: 'AxiformaSemiBold',
			color: '#000',
			fontSize: "14px",
		}
	}

	function NoRowsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}

	function NoResultsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}
	const deleteOrders = async () => {
		let obj = {
			"ids": selectedIds
		}
		try {
			let message;
			await dispatch(userActions.deleteMerchants(obj)).unwrap();
			message = "Merchant deleted successfully";
			dispatch(alertActions.success({ message, showAfterRedirect: true }));
			setSelectedIds([])

			dispatch(userActions.getAll()).unwrap()
		} catch (error) {
			dispatch(alertActions.error(error));
		}
	}


	return (
		<div className={Style.sections} style={{ marginLeft: mobile ? mobile.class : "290px" }}>
			<div className={Style.main_container} >
				<div className={Style.head}>
					<h1 className={Style.title}>Merchants</h1>
					<div className={Style.add_search}>
						{selectedIds.length > 0 && <button onClick={() => { deleteOrders() }} className={Style.delete_merchant}> <DeleteIcon />Delete Merchant</button>}
						<div className={Style.search}>
							<Input
								sx={{ height: "100%", width: "100%", padding: "10px" }}
								placeholder="Search by ID, Name"
								// onChange={e => setSearch(e.target.value)} className={Style.search_field} 
								onChange={handleChange}
								value={searchValue}
							/>

							<div onClick={handleClick} className={Style.serach_box} >
								<SearchIcon className={Style.search_icons} /></div>
						</div>
						<Link onClick={() => {
							setEditUser('')
							handleOpen()
						}} className={Style.add_merchant}>
							<AddIcon color='#fff' />
							<span className={Style.link}>Add new merchants</span>
						</Link>
					</div>
				</div>

				<div className={Style.userList}>
					<Box sx={{ height: "100%", width: '100%', background: "#fff" }}>
						<DataGrid
							headerHeight={80}
							rowHeight={80}
							sx={grigStyle}
							className={Style.data}
							rows={rows}
							columns={columns}
							components={{ NoRowsOverlay, NoResultsOverlay }}
							onRowSelectionModelChange={(newRowSelectionModel) => { setSelectedIds(newRowSelectionModel) }}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[5, 10, 15]}
							checkboxSelection
							disableRowSelectionOnClick
						/>
					</Box>
				</div>
			</div>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						TransitionComponent: Fade,
					},
				}}
			>
				<Fade in={open}>
					<Box className={Style.modelBox}>
						<CloseIcon className={Style.closeBtn} onClick={handleClose} />
						<AddEdit handleClose={handleClose} id={editUser ? editUser.id : ""} />
					</Box>

				</Fade>
			</Modal>
		</div>
	);
}
