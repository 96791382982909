import { useEffect, useState, useRef } from "react";
import QRcode from "qrcode.react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
// import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector, useDispatch } from "react-redux";
import Style from "./style.module.css";
import Styles from "../account/style.module.css";
import { orderActions, locationActions, alertActions } from "_store";
import { Loader } from "../../_components/loader";
import QRCodee from "qrcode";
import Cancel from '../../_assets/images/cancelled.png';
import Request from '../../_assets/images/request.png';
import Pickup from '../../_assets/images/pickup.png';
import Delivered from '../../_assets/images/check.png';
import OnTheWay from '../../_assets/images/route.png';
import Delayed from '../../_assets/images/time.png';

// import { history } from "_helpers";
import { jsPDF } from "jspdf";
import { UsersLayout } from "_screen/merchants";
import moment from "moment";

export { EditOrder };

function EditOrder({ handleClose, id }) {
  const orderId = useParams().id;
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");
  const mobile = useSelector((x) => x.mobile.value);
  const [disabled, setDisabled] = useState(false);
  const [lastphone, setLastPhone] = useState();
  const [dropOff, setDropOff] = useState([]);
  const [pickUp, setPickUp] = useState([]);
  const [order, setOrder] = useState(false);
  const [combinedAddress, setCombinedAddress] = useState("");
  const [role, setRole] = useState("");
  const [trackingURL, setTrackingURL] = useState("");
  const [trackingID, setTrackingID] = useState("");
  const [merchant, setMerchant] = useState("");
  const [receiverInfo, setReceiverInfo] = useState({
    receiverName: "",
    contactNumber: "",
    address: "",
  });

  const location = useLocation();
  useEffect(() => {
    setRole(localStorage.getItem("role"));
    const queryParams = new URLSearchParams(location.search);
    const param1 = queryParams.get("merchant");
    setMerchant(param1);
  }, []);



  // console.log(merchant);

  const qrCodeRef = useRef(null);
  const generateQRCodeData = () => {
    // console.log(receiverInfo);
    return JSON.stringify(receiverInfo);
  };

  async function generateCode(id) {
    try {
      // const qrCodeDataURL = await QRCodee.toDataURL("https://www.iships.ca/?orderId=" + id);
      const qrCodeDataURL = await QRCodee.toDataURL(id);
      return qrCodeDataURL
    } catch (error) {
      console.log('qr error', error);
      return ""
    }
  };


  //DOWNLOAD QR DOCE
  const downloadQR = async () => {
    var pickUpData = await pickUp.filter((ele) => ele.name == (order.pickup ? order.pickup : ""));
    if (pickUpData.length == 0) {
      pickUpData = [firstActivePickup]
    }
    var pickUpLineBreak = pickUpData && pickUpData.length != 0 ? ((Number(pickUpData[0].address.length) + Number(pickUpData[0].city.length)) >= 36 ? true : false) : false;
    var notPickupLocation = 0;
    var notPickupLocation = ((order && order.pickup && order.pickup != "") || firstActivePickup ? notPickupLocation : notPickupLocation + 1);
    if (notPickupLocation == 0) {
      const pdfWidth = 4;
      const pdfHeight = 6;
      const pdf = new jsPDF({
        unit: "in",
        format: [pdfWidth, pdfHeight],
        orientation: "portrait"
      });
      var orderWithQR = order;
      var lineBreak = orderWithQR?.shipping_address?.address1 && orderWithQR?.shipping_address?.address1 != "undefined" && (Number(orderWithQR?.shipping_address?.address1.length) + Number(orderWithQR?.shipping_address?.address2 != "" && orderWithQR?.shipping_address?.address2 != undefined && orderWithQR?.shipping_address?.address2 != null ? orderWithQR?.shipping_address?.address2.length : 0)) >= 25 ? true : false;
      var qr = "";
      await generateCode(order._id).then((data) => {
        qr = data;
      })
      setTimeout(() => {
        var totalPage = 0;
        totalPage = (orderWithQR.noOfItems == null || orderWithQR.noOfItems == 0 ? 1 : orderWithQR.noOfItems);
        console.log('totalPage', totalPage);
        console.log('orderWithQR', orderWithQR);
        for (let i = 0; i < totalPage; i++) {
          const from = orderWithQR?.user?.businessLocation || orderWithQR?.user?.businessAddress;
          pdf.setFontSize(12);
          pdf.setFont("Roboto", "bold");
          const fromText = pdf.splitTextToSize(`${from}`, 1.5);
          var manualOrder = "";
          if (orderWithQR.orderNo == "") {
            if (orderWithQR.manualOrderNo) {
              switch (String(orderWithQR.manualOrderNo).length) {
                case 1: manualOrder = `M-000${orderWithQR.manualOrderNo}`;
                  break;
                case 2: manualOrder = `M-00${orderWithQR.manualOrderNo}`;
                  break;
                case 3: manualOrder = `M-0${orderWithQR.manualOrderNo}`;
                  break;
                case 4: manualOrder = `M-${orderWithQR.manualOrderNo}`;
                  break;
              }
            } else {
              manualOrder = "M"
            }
          }
          pdf.setLineWidth(0.01);
          pdf.line(0, 0.90, 4, 0.90); // horizontal line  
          pdf.text(`#Order: ${orderWithQR.orderNo == "" ? manualOrder : orderWithQR.orderNo}`, 2.00, 0.65, 'center') //
          pdf.setFontSize(9);
          pdf.setFont("Roboto", "bold");
          pdf.text(`Shipper `, 0.30, 1.20);
          pdf.setFont("Roboto", "");
          pdf.text(`${pickUpData && pickUpData.length != 0 && pickUpData[0].name ? pickUpData[0].name : ""}`, 0.30, 1.40);
          { pickUpLineBreak == false ? pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].address + ", " + pickUpData[0].city : ""}`, 0.30, 1.60) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].address + ", " : ""}`, 0.30, 1.60) }
          { pickUpLineBreak == false ? pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].state + ", " + pickUpData[0].zipCode : ""}`, 0.30, 1.80) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].city : ""}`, 0.30, 1.80) }
          { pickUpLineBreak == false ? pdf.text(``, 0.30, 2.00) : pdf.text(`${pickUpData && pickUpData.length != 0 ? pickUpData[0].state + ", " + pickUpData[0].zipCode : ""}`, 0.30, 2.00) }
          pdf.setFontSize(13);
          pdf.setFont("Roboto", "");
          pdf.text(`1/${i + 1}`, 3.40, 0.65);
          { orderWithQR?.id_card || orderWithQR?.insurance ? pdf.addImage(qr, 'JPEG', 1.15, 3.85, 1.70, 1.70) : pdf.addImage(qr, 'JPEG', 1.15, 3.65, 1.70, 1.70) }
          pdf.setFontSize(10);
          pdf.setFont("Roboto", "bold");
          pdf.text(`Receiver`, 0.30, 2.40);
          pdf.setFontSize(9);
          pdf.setFont("Roboto", "bold");
          pdf.text(`Name: `, 0.30, 2.60)
          { lineBreak == false ? pdf.text(`Address: `, 0.30, 2.80) : pdf.text(`Address: `, 0.30, 2.80) }
          { lineBreak == false ? pdf.text(`Contact: `, 0.30, 3.00) : pdf.text(`Contact: `, 0.30, 3.20) }
          if (orderWithQR?.id_card || orderWithQR?.insurance || orderWithQR?.signature) {
            lineBreak == false ? pdf.text(`Instructions: `, 0.30, 3.20) : pdf.text(`Instructions: `, 0.30, 3.40)
          }
          pdf.setFont("Roboto", "");

          { lineBreak == false ? pdf.text(`${(orderWithQR?.shipping_address?.address1 && orderWithQR?.shipping_address?.address1 == "undefined" ? "" : orderWithQR?.shipping_address?.address1 + (orderWithQR?.shipping_address && orderWithQR?.shipping_address?.address2 != null && orderWithQR?.shipping_address?.address2 != "" && orderWithQR?.shipping_address?.address2 != undefined ? " | " + orderWithQR?.shipping_address?.address2 : "")) + (orderWithQR?.shipping_address.city ? ", " + orderWithQR?.shipping_address.city : "") + (orderWithQR?.shipping_address.province ? ", " + orderWithQR?.shipping_address.province : "") + (orderWithQR?.shipping_address.zip ? ", " + orderWithQR?.shipping_address.zip : "")}`, 0.82, 2.80) : pdf.text(`${(orderWithQR?.shipping_address?.address1 && orderWithQR?.shipping_address?.address1 == "undefined" ? "" : orderWithQR?.shipping_address?.address1 + " | " + (orderWithQR?.shipping_address && orderWithQR?.shipping_address?.address2 != null && orderWithQR?.shipping_address?.address2 != "" && orderWithQR?.shipping_address?.address2 != undefined ? " | " + orderWithQR?.shipping_address?.address2 : "")) + ","}`, 0.82, 3.00) }

          { lineBreak == false ? pdf.text(`${orderWithQR?.shipping_address?.name != "undefined" ? orderWithQR?.shipping_address?.name : (orderWithQR?.customer?.first_name ? orderWithQR?.customer?.first_name : "") + " " + (orderWithQR?.customer?.last_name ? orderWithQR?.customer?.last_name : "")}`, 0.74, 2.60) : pdf.text(`${(orderWithQR?.shipping_address.city ? orderWithQR?.shipping_address.city : "") + (orderWithQR?.shipping_address.province ? ", " + orderWithQR?.shipping_address.province : "") + (orderWithQR?.shipping_address.zip ? ", " + orderWithQR?.shipping_address.zip : "")}`, 0.82, 2.80) }

          { lineBreak == false ? pdf.text(`${orderWithQR?.shipping_address.phone != "" ? orderWithQR?.shipping_address.phone : ""}`, 0.82, 3.00) : pdf.text(`${orderWithQR?.shipping_address?.name != "undefined" ? orderWithQR?.shipping_address?.name : (orderWithQR?.customer?.first_name ? orderWithQR?.customer?.first_name : "") + " " + (orderWithQR?.customer?.last_name ? orderWithQR?.customer?.first_name : "")}`, 0.74, 2.60) }

          { lineBreak == false ? pdf.text(``, 0.82, 3.00) : pdf.text(`${orderWithQR?.shipping_address.phone != "" ? orderWithQR?.shipping_address.phone : ""}`, 0.82, 3.20) }

          pdf.setLineWidth(0.01);
          { orderWithQR?.id_card || orderWithQR?.insurance || orderWithQR?.signature ? lineBreak == false ? pdf.line(0, 3.65, 4, 3.65) : pdf.line(0, 3.85, 4, 3.85) : lineBreak == false ? pdf.line(0, 3.40, 4, 3.40) : pdf.line(0, 3.60, 4, 3.60) } // horizontal line 

          { lineBreak == false ? pdf.text(`${orderWithQR.id_card ? "ID is required" : ""}${orderWithQR.signature ? (orderWithQR.id_card ? ", Signature is required" : "Signature is required") : ""}${orderWithQR.insurance ? (orderWithQR.id_card || orderWithQR.signature ? ", Insurance is required" : "Insurance is required") : ""}`, 0.30, 3.40) : pdf.text(`${orderWithQR.id_card ? "ID is required" : ""}${orderWithQR.signature ? (orderWithQR.id_card ? ", Signature is required" : "Signature is required") : ""}${orderWithQR.insurance ? (orderWithQR.id_card || orderWithQR.signature ? ", Insurance is required" : "Insurance is required") : ""}`, 0.30, 3.60) }

          // { lineBreak == false ? pdf.text(`${orderWithQR.insurance ? (orderWithQR.id_card || orderWithQR.signature ? "Insurance is required" : "Insurance is required") : ""}`, 1.05, orderWithQR.id_card ? 3.40 : orderWithQR.signature ? 3.40 : 3.20) : pdf.text(`${orderWithQR.insurance ? (orderWithQR.id_card || orderWithQR.signature ? "Insurance is required" : "Insurance is required") : ""}`, 1.05, orderWithQR.id_card ? 3.60 : orderWithQR.signature ? 3.80 : 3.40) }

          pdf.addImage('/images/logoLight.png', 'JPEG', 0.30, 0.40, 0.60, 0.35);
          if (i != (totalPage - 1)) {
            pdf.addPage();
          }
        }
        pdf.save(`Label.pdf`);
      }, 2000);
    } else {
      let message = "Please add pickup location.";
      dispatch(alertActions.error({ message, showAfterRedirect: true }));
    }
  };

  const dispatch = useDispatch();
  const orders = useSelector((x) => x.order.list);

  // form validation rules
  const validationSchema = Yup.object().shape({
    orderNo: Yup.string(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [modifiedphone, setmodifiedphone] = useState("")
  useEffect(() => {
    if (orderId) {
      let searchObj = {
        search: "",
        status: "",
        date: [],
      };
      dispatch(orderActions.getAll(searchObj)).then((data) => {
        // console.log(data);
        const orderArr = data?.payload?.data?.orders;
        let item = orderArr?.filter((c) => {
          const orderObj = c._id === orderId;
          return orderObj;
        });

        // console.log("orderArr", item);
        // console.log("orderArr", item[0]);
        setReceiverInfo({
          receiverName: `${item[0]?.customer?.first_name} ${item[0]?.customer?.last_name}`,
          contactNumber: `${item[0]?.customer?.phone || `N/A`}`,
          addres: `${item[0]?.shipping_address?.address1 ||
            `${item[0]?.shipping_address.city}, ${item[0]?.shipping_address.province}, ${item[0]?.shipping_address.country}, ${item[0]?.shipping_address.zip}` ||
            `N/A`
            }`,
        });
        reset(item[0]);
        setOrder(item[0]);
        setTrackingURL(item[0] && item[0]._id ? "https://www.iships.ca/?orderId=" + item[0]._id : "NA");
        setTrackingID(item[0] && item[0]._id ? item[0]._id : "NA");
        var cAddress = ((item[0] && item[0].shipping_address && item[0].shipping_address.address1 != "" ? item[0].shipping_address.address1 : "") + (item[0] && item[0].shipping_address && item[0].shipping_address.address2 ? " | " + item[0].shipping_address.address2 : ""));
        setCombinedAddress(cAddress);
        if (item[0].order_status_trail && item[0].order_status_trail.length != 0) {
          setDropOff(item[0].order_status_trail);
        }
        setLabel("Update");
        setTitle("Update order");
        setmodifiedphone(convertNumber(item[0] && item[0].shipping_address ? item[0].shipping_address.phone : ""))
      });
    } else {
      setLabel("Create");
      setTrackingURL("NA");
      setTrackingID("NA")
      setTitle("Create order");
    }
  }, []);

  useEffect(() => {
    try {
      // setTimeout(() => {
      dispatch(locationActions.getAll()).then((data) => {
        // if(data && data?.payload && data?.payload?.data && data?.payload?.data?.locations && order){
        // var merchantLocation = data?.payload?.data?.locations.filter((e) =>  e.user == (order.user._id ? order.user._id : order.user.id) );
        // console.log('merchantLocation', merchantLocation);
        setPickUp(data?.payload?.data?.locations.length != 0 ? data?.payload?.data?.locations : [])
        // }
        // else{
        //   setPickUp([])
        // }
      });
      // }, 1000);
    } catch (error) {

    }
  }, [])

  function convertNumber(data) {
    var numericString = data.replace(/[^\d]/g, '');
    if (numericString.length > 11) {
      return numericString;
    }
    if (numericString && numericString.length) {
    }
    if (modifiedphone && modifiedphone.length) {
    }
    if (lastphone && lastphone.length) {
    }
    if (modifiedphone && data.length < modifiedphone.length) {
      if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-") {
        return `${data.slice(0, data.length - 1)}`;
      }
      else {
        return `${data.slice(0, data.length)}`;
      }
    }
    else {
      if (numericString.charAt(0) == '1') {
        numericString = numericString.slice(1);
      }
      if (numericString.length >= 2) {
        setLastPhone(numericString)
        return `+1 (${numericString.slice(0, 3)}) ${numericString.slice(3, 6)}-${numericString.slice(6)}`;
      }
      if (numericString.length >= 2) {
        if (numericString.charAt(0)) {
          numericString = numericString.slice(1);
        }
        var format2 = ''
        var format3 = ''
        if (numericString.length > 3) {
          format2 = numericString.slice(3, 6)
          format3 = numericString.slice(6)
        }

        if (lastphone && lastphone.length) {
        }
        if (modifiedphone && modifiedphone.length) {
        }
        if (modifiedphone && modifiedphone.length && lastphone && lastphone.length && lastphone.length <= modifiedphone.length) {
          setLastPhone(numericString)
          return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
        }
        else {
          if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == " " || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == ")" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "(") {
            setLastPhone(numericString)
            return `${data.slice(0, data.length - 1)}`;
          }
          else {
            setLastPhone(numericString)
            return `+1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
          }
        }
      }
    }
  }

  async function onSubmit(data) {
    const nameArray = data.shipping_address?.name?.split(' ');
    // Extract the first and last names
    const firstName = nameArray ? nameArray[0] : "";
    const lastName = nameArray?.length > 1 ? nameArray.slice(1).join(' ') : "";
    data.customer.first_name = firstName
    data.customer.last_name = lastName;
    // if (combinedAddress.includes(" | ")) {
    //   data.shipping_address.address1 = combinedAddress.split(" | ")[0]
    //   data.shipping_address.address2 = combinedAddress.split(" | ")[1]
    // } else {
    //   data.shipping_address.address1 = combinedAddress
    //   data.shipping_address.address2 = "";
    // }
    ;
    // console.log(data, "data")
    // dispatch(alertActions.clear());
    setDisabled(true);
    try {
      if (data.amount == null) {
        data.amount = ""
      }
      // create or update user based on id param
      let message;
      if (orderId) {
        let orderID = order?._id;
        data.shipping_address.phone = modifiedphone;
        await dispatch(orderActions.update({ orderID, data })).unwrap();
        message = "Order has been updated";
        // history.navigate("/");
        let searchObj = {
          search: "",
          status: "",
          date: [],
        };
        dispatch(orderActions.getAll(searchObj)).then((data) => {
          // console.log(data);
          const orderArr = data?.payload?.data?.orders;
          let item = orderArr?.filter((c) => {
            const orderObj = c._id === orderId;
            return orderObj;
          });

          setReceiverInfo({
            receiverName: `${item[0]?.customer?.first_name} ${item[0]?.customer?.last_name}`,
            contactNumber: `${item[0]?.customer?.phone || `N/A`}`,
            addres: `${item[0]?.shipping_address?.address1 ||
              `${item[0]?.shipping_address.city}, ${item[0]?.shipping_address.province}, ${item[0]?.shipping_address.country}, ${item[0]?.shipping_address.zip}` ||
              `N/A`
              }`,
          });
          setOrder(item[0]);
          // setLabel("Update");
          // setTitle("Update order");
        });
      } else {
        data.shipping_address.province = "Ontario"
        data.shipping_address.country = "CA"
        data.pickup = data.pickup == "" ? defaultPickup : data.pickup;
        if (merchant) {
          data.merchant = merchant;
          delete data.user;
          await dispatch(orderActions.createSingle({ data })).unwrap();
          message = "Order has been created";
          // history.navigate("/");
        } else {
          delete data.user;
          await dispatch(orderActions.createSingle({ data })).unwrap();
          message = "Order has been created";
          // history.navigate("/");
        }
      }

      dispatch(alertActions.success({ message, showAfterRedirect: true }));
      let searchObj = {
        search: "",
        status: "",
        date: [],
      };
      dispatch(orderActions.getAll(searchObj));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    setTimeout(() => {
      setDisabled(false);
    }, 2500);
  }

  const pushOptimo = async () => {
    try {
      let message;
      let id = order?._id;
      await dispatch(orderActions.pushOptimo(id)).unwrap();
      message = "Order has been pushed to optimo";

      dispatch(alertActions.success({ message, showAfterRedirect: true }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };


  const handleTrackUrl = async () => {
    try {
      if (trackingURL == "NA") { return }
      window.open(trackingURL, "_blank");
    } catch (error) {
      console.log('error', error);

    }
  };
  const firstActivePickup = pickUp?.find(item => item.status === 'Active');
  const loading = (order && pickUp) || orderId == undefined ? false : true
  const defaultPickup = order.pickup && order.pickup != "" ? order.pickup : firstActivePickup && firstActivePickup?.name
  return (
    <div>
      {!loading &&
        <div
          className={Style.order_mainContainer}
          style={{ marginLeft: mobile ? mobile.class : "290px" }}
        >
          <div className={Style.main_container_edit}>
            <div className={Style.head}>
              <h1 className={Style.title} style={{ textAlign: "left" }}>
                {title}
              </h1>
              {/* {console.log("order", order)} */}
              <div className={Style.button_wrapper} style={{ gap: "20px" }}>
                {orderId && (
                  <button className={Style.download_btn} onClick={downloadQR}>
                    <QrCode2Icon /> Generate Label
                  </button>
                )}
                {orderId &&
                  role === "admin" &&
                  order?.optimoPushedStatus && order?.optimoPushedStatus === "requested" && (
                    <button className={Style.download_btn} onClick={pushOptimo}>
                      {/* <AllInclusiveIcon />  */}
                      Push Optimo
                    </button>
                  )}
                {orderId &&
                  role === "admin" &&
                  order?.optimoPushedStatus && order?.optimoPushedStatus !== "requested" && (
                    <button
                      className={Style.download_btn}
                      disabled
                      style={{
                        cursor: "not-allowed",
                      }}
                    >
                      {/* <AllInclusiveIcon />  */}
                      Push Optimo
                    </button>
                  )}
                {orderId && role === "user" && (
                  <button
                    className={Style.download_btn}
                    onClick={async () => {
                      if (order?.shipping_address?.phone) {
                        const res = await dispatch(
                          orderActions.requestToShip({ orderIdsArray: [orderId], fromEdit: true })
                        );
                        if (res?.payload?.success) {
                          // downloadQR();
                          if (order.optimoPushedStatus === "requested") {
                            setOrder({
                              ...order,
                              optimoPushedStatus: "cancelled",
                            });
                          } else if (order.optimoPushedStatus !== "cancelled") {
                            setOrder({
                              ...order,
                              optimoPushedStatus: "requested",
                            });
                          }
                        }
                        let message =
                          order.optimoPushedStatus === "requested"
                            ? "Pickup request cancelled"
                            : "Pickup request sent";
                        dispatch(
                          alertActions.success({ message, showAfterRedirect: true })
                        );
                      } else {
                        let message = "Please add customer phone number";
                        dispatch(
                          alertActions.error({ message, showAfterRedirect: true })
                        );
                      }
                    }}
                    disabled={order?.optimoPushedStatus === "pushed"}
                    style={{
                      cursor:
                        order?.optimoPushedStatus === "pushed"
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    {/* <AllInclusiveIcon /> */}{" "}
                    {order.optimoPushedStatus === "requested"
                      ? "Cancel pickup request"
                      : "Request pickup"}
                  </button>
                )}
                <Link to={`/`} className={Style.col_btn}>
                  <ArrowBackIosNewIcon style={{ height: "15px", width: "20px" }} />{" "}
                  <span>Back</span>
                </Link>
              </div>
            </div>
            <div style={{ display: "none" }} ref={qrCodeRef}>
              <QRcode
                id="myqr"
                value={generateQRCodeData()}
                size={320}
                includeMargin={true}
              />
            </div>
            <div className={Style.wrapper}>
              <div className={Style.details} style={label == "Create" ? { display: "none" } : { display: "block" }}>
                <div className={Style.head_detail}>
                  <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                    Tracking Information
                  </h1>
                </div>
                <div className={Style.detail_wrapper}>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Track Url</p>
                      <input
                        // placeholder="Track Url"
                        name="trackurl"
                        type="text"
                        value={trackingURL}
                        className={Style.no_inputs}
                        disabled
                      // onClick={(e) => handleTrackUrl()}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNo?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Track ID</p>
                      <input
                        className={Style.no_inputs}
                        // placeholder="Track ID"
                        name="trackId"
                        type="text"
                        value={trackingID}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.details}>
                <div className={Style.head_detail}>
                  <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                    Order Information
                  </h1>
                  <span className={`${order?.status} status`}>{order?.status}</span>
                </div>
                <div className={Style.detail_wrapper}>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "none" } : (order && order.orderNo == "" ? { display: "none" } : { display: "block" })}>
                      <p className={Style.label}>Order ID</p>

                      <input
                        placeholder="Order ID"
                        {...register("orderNo")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.orderNo?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "none" } : (order && order.orderNo == "" ? { display: "none" } : { display: "block" })}>
                      <p className={Style.label}>Total Weight</p>
                      <input
                        value={order?.totalWeight}
                        placeholder="Total Weight"
                        name="weight"
                        type="text"
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNo?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "none" } : (order && order.orderNo == "" ? { display: "none" } : { display: "block" })}>
                      <p className={Style.label}>Subtotal Price</p>
                      <input
                        placeholder="amount"
                        name="amount"
                        type="text"
                        {...register("amount")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.amount?.message}
                      </div>
                    </div>
                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "none" } : (order && order.orderNo == "" ? { display: "none" } : { display: "block" })}>
                      <p className={Style.label}>Customer Id</p>
                      <input
                        placeholder="Customer ID"
                        name="customerid"
                        type="text"
                        {...register("customer.id")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>
                    {/* <div className={Styles.input_wrapper}>
                  <p className={Style.label}>First Name</p>
                  <input
                    placeholder="First Name"
                    name="firstName"
                    type="text"
                    {...register("customer.first_name")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Last Name</p>
                  <input
                    placeholder="Last Name"
                    name="lastName"
                    type="text"
                    {...register("customer.last_name")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div> */}
                    {/* <div className={Styles.input_wrapper} style={label == "Create" ? { display: "block" } : { display: "none" }}>
                  <p className={Style.label}>Email Address</p>
                  <input
                    placeholder="Customer Email"
                    name="customerEmai"
                    type="text"
                    {...register("customer.email")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div> */}
                  </div>
                  <div className={Style.detail_row}>

                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Package Size</p>
                      <select className={Style.inputs} {...register("packageSize")}>
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                        <option value="X-Large">X-Large</option>
                      </select>
                    </div>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "block" } : { display: "block" }}>
                      <p className={Style.label}>No. of Boxes</p>
                      <input
                        placeholder="No. Of Boxes in order"
                        name="noOfItems"
                        type="number"
                        {...register("noOfItems")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "block" } : { display: "block" }}>
                      <p className={Style.label}>Pickup Location</p>
                      <select className={Style.inputs} {...register("pickup")} defaultValue={defaultPickup}>
                        {role == "user" ? <option value="">Select</option> : <></>}
                        {role == "user" && pickUp && pickUp.length && pickUp.length != 0 ? (pickUp.map((element, index) => {
                          return (
                            <option value={element.name} selected={defaultPickup == element.name ? true : false}>{element.name}</option>
                          )
                        })) : <></>}
                      </select>
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>
                    {/* <div className={Styles.input_wrapper} style={label == "Create" ? { display: "block" } : { display: "none" }}>
                  <p className={Style.label}>No. of Boxes</p>
                  <input
                    placeholder="No. Of Boxes in order"
                    name="noOfItems"
                    type="number"
                    {...register("noOfItems")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div> */}
                  </div>
                  <div className={Styles.check_box_row}>
                    <div className={Styles.inside_checkbox}>
                      <input type="checkbox" name="id" className={Styles.inputCheckbox} {...register(`id_card`)} />
                      <label className={Style.label}>ID</label>
                    </div>
                    <div className={Styles.inside_checkbox}>
                      <input type="checkbox" name="Signature" className={Styles.inputCheckbox} {...register(`signature`)} />
                      <label className={Style.label}>Signature</label>
                    </div>
                    <div className={Styles.inside_checkbox}>
                      <input type="checkbox" name="Insurance" className={Styles.inputCheckbox} {...register(`insurance`)} />
                      <label className={Style.label}>Insurance</label>
                    </div>
                  </div>
                  <div className={Style.detail_row}>

                    <div></div>
                  </div>
                </div>
              </div>
              {/* <div className={Style.details}>
            <div className={Style.head_detail}>
              <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                Billing Address
              </h1>
            </div>
            <div className={Style.detail_wrapper}>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Full Name</p>
                  <input
                    placeholder="Full Name"
                    name="billingfn"
                    type="text"
                    {...register(`billing_address.name`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Phone No.</p>
                  <input
                    placeholder="Phone Number"
                    name="billingPhone"
                    type="text"
                    {...register(`billing_address.phone`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}></div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Country Code</p>
                  <input
                    placeholder="Country"
                    name="country"
                    type="text"
                    {...register(`billing_address.country`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Province Code</p>
                  <input
                    placeholder="Province"
                    name="province"
                    type="text"
                    {...register(`billing_address.province`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>City</p>
                  <input
                    placeholder="City"
                    name="city"
                    type="text"
                    {...register(`billing_address.city`)}
                    className={Style.inputs}
                  />
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Address</p>
                  <input
                    placeholder="Address"
                    name="address"
                    type="text"
                    {...register(`billing_address.address1`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Postal Code</p>
                  <input
                    placeholder="Zip Code"
                    name="zip"
                    type="text"
                    {...register(`billing_address.zip`)}
                    className={Style.inputs}
                  />
                </div>
              </div>
            </div>
          </div> */}

              <div className={Style.details}>
                <div className={Style.head_detail}>
                  <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                    Shipping Address
                  </h1>
                </div>
                <div className={Style.detail_wrapper}>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Name</p>
                      <input
                        placeholder="Name"
                        name="shippingfn"
                        type="text"
                        {...register(`shipping_address.name`)}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNo?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper} style={label == "Create" ? { display: "block" } : { display: "block" }}>
                      <p className={Style.label}>Email Address</p>
                      <input
                        placeholder="Customer Email"
                        name="customerEmai"
                        type="text"
                        {...register("customer.email")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Phone No.</p>
                      <input
                        placeholder="1 416-555-4481"
                        name="customerPhone"
                        type="text"
                        value={modifiedphone}
                        onChange={(e) => {
                          var mphon = convertNumber(e.target.value)
                          setmodifiedphone(mphon)
                          setValue("shipping_address.phone", mphon)
                        }}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNo?.message}
                      </div>
                    </div>

                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Address 1</p>
                      <input
                        className={Style.inputs}
                        placeholder="Address"
                        name="address"
                        type="text"
                        {...register(`shipping_address.address1`)}
                      // onChange={(e) => {
                      //   console.log('e', e.target.value);
                      //   setCombinedAddress(e.target.value)
                      // }}
                      />
                    </div>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Apartment/Unit #</p>
                      <input
                        className={Style.inputs}
                        placeholder="Apartment/Unit #"
                        name="address"
                        type="text"
                        {...register(`shipping_address.address2`)}
                      // value={combinedAddress}
                      // onChange={(e) => {
                      //   console.log('e', e.target.value);
                      //   setCombinedAddress(e.target.value)
                      // }}
                      />
                    </div>

                    {/* <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Country</p>
                  <input
                    placeholder="Country"
                    name="country"
                    type="text"
                    value="CA"
                    disabled
                    {...register(`shipping_address.country`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div> */}
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>City</p>
                      <input
                        placeholder="City"
                        name="city"
                        type="text"
                        {...register(`shipping_address.city`)}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>

                    {/* <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Province</p>
                  <input
                    placeholder="Province"
                    name="province"
                    value={"Ontario"}
                    disabled
                    type="text"
                    {...register(`shipping_address.province`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div> */}

                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Postal Code</p>
                      <input
                        placeholder="A2B 3C4"
                        name="zip"
                        type="text"
                        {...register(`shipping_address.zip`)}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNo?.message}
                      </div>
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Special Instructions</p>
                      <textarea
                        className={Style.inputs}
                        {...register(`shipping_address.specialInstructions`)}
                        style={{
                          height: "100px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.details}>
                <div className={Style.head_detail}>
                  <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                    DropOff
                  </h1>
                  {/* <span className={`${order?.status} status`}>{order?.status}</span> */}
                </div>
                <div className={Style.detail_wrapper_status}>
                  {dropOff.length != 0 ? dropOff.map((dropStatus, index) => {
                    return (<div className={Style.detail_roww}>
                      <div style={dropStatus.status == "requested" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.checked_image} src={Request} alt="status_image" />
                      </div>
                      <div style={dropStatus.status == "pickedUp" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.close_image} src={Pickup} alt="status_image" />
                      </div>
                      <div style={dropStatus.status == "cancelled" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.close_image} src={Cancel} alt="status_image" />
                      </div>
                      <div style={dropStatus.status == "delivered" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.close_image} src={Delivered} alt="status_image" />
                      </div>
                      <div style={dropStatus.status == "delayed" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.close_image} src={Delayed} alt="status_image" />
                      </div>
                      <div style={dropStatus.status == "onTheWay" ? { display: "block" } : { display: "none" }}>
                        <img className={Style.close_image} src={OnTheWay} alt="status_image" />
                      </div>
                      <div className={dropStatus.status == "cancelled" ? Style.input_wrapper_status_canceled : Style.input_wrapper_status_sucess}>
                        {dropStatus.status.toUpperCase()}
                        <span >{"(" + moment(dropStatus.createdAt).format('DD/MM/YYYY hh:mm A') + ")"}</span>
                      </div>
                    </div>
                    )
                  }) : <></>}
                </div>
              </div>
            </div>
            {!(order?.loading || order?.error) && (
              <form onSubmit={handleSubmit(onSubmit)} className={Style.order_form}>
                <div className={Style.input_container}>
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div>

                <div className={Style.button_wrapper} style={{ gap: "20px" }}>
                  <button
                    type="submit"
                    disabled={disabled}
                    className={Style.input_btn}
                  >
                    {isSubmitting && disabled && <span className="spinner"></span>}
                    {label}
                  </button>
                </div>
              </form>
            )}
            {order?.loading && (
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Loader />
              </div>
            )}
            {order?.error && (
              <div class="text-center m-5">
                <div class="text-danger">Error loading user: {orders.error}</div>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
}
