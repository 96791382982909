import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import Styles from "../account/style.module.css";
import { orderActions, alertActions } from '_store';
import { Loader } from '../../_components/loader';


export { ShopifyImport };

function ShopifyImport({ handleClose, id }) {
    // console.log("id", id);
    const [disabled, setDisabled] = useState(false);
    const [update, setUpdate] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState(false);
    const [error, setError] = useState({
        shopifyAccessToken: "",
        date: "",
        link: ""
    });
    const [state, setState] = useState({
        shopifyAccessToken: "",
        date: "",
        link: ""
    });
    // const [connectedData, setConnectedData]  = useState();
    // Add these variables to your component to track the state

    const dispatch = useDispatch();
    const location = useSelector(x => x.location.list);

    useEffect(() => {
        var importDetail = localStorage.getItem('import');
        if (importDetail) {
            importDetail = JSON.parse(importDetail);
        }
        if (importDetail && importDetail.shopifyAccessToken && importDetail.date && importDetail.linkk) {
            if (state.shopifyAccessToken == "" && update == false) {
                setState({
                    shopifyAccessToken: importDetail.shopifyAccessToken,
                    date: importDetail.date,
                    link: importDetail.linkk
                })
                setUpdate(true)
            }
        }
    })

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }
    function handleError() {
        if (state.shopifyAccessToken == "") {
            setError({
                ...error,
                ["shopifyAccessToken"]: "Api key is required"
            });
            setErrorUpdate(true)
            return true
        }
        if (state.date == "") {
            setError({
                ...error,
                ["date"]: "Event Version is required"
            });
            setErrorUpdate(true)
            return true
        }
        if (state.link == "") {
            setError({
                ...error,
                ["link"]: "Store url is required"
            });
            setErrorUpdate(true)
            return true
        }
        return false
    }


    async function handleClick() {
        dispatch(alertActions.clear());
        setDisabled(true)
        try {
            
            // create or update user based on id param
            const forward = handleError();
            if (!forward) {
                let message;
                var data = {
                    shopifyAccessToken: state.shopifyAccessToken,
                    date: state.date,
                    linkk: state.link
                };
                await dispatch(orderActions.importShopify(data)).unwrap();
                message = 'Order has been import successfully';

                // redirect to location list with success message
                let searchObj =
                {
                    "search": '',
                    "status": '',
                    "date": ''
                }
                dispatch(orderActions.getAll(searchObj));
                handleClose(false)
                localStorage.setItem("import", JSON.stringify(data))
                setErrorUpdate(false);
                dispatch(alertActions.success({ message, showAfterRedirect: true }));
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            } else {
                dispatch(alertActions.error(error));
            }
        } catch (error) {
            dispatch(alertActions.error(error));
        }
        setTimeout(() => {
            setDisabled(false)
        }, 2500);
    }

    return (
        <div className={Style.sections_edit}>
            <div className={Style.main_container_shopify}>
                <h1 style={{ textAlign: "center" }} className={Style.title}>Import from shopify</h1>
                {!(location?.loading || location?.error) &&
                    <form style={{ marginTop: "50px" }}>
                        <div className={Styles.input_wrapper}>
                            <input placeholder='Your Api Key' name="shopifyAccessToken" type="text" value={state.shopifyAccessToken} onChange={handleChange}
                                className={`${errorUpdate && error.shopifyAccessToken ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errorUpdate ? error.shopifyAccessToken : ""}</div>
                        </div>
                        <div className={Styles.input_wrapper}>
                            <input placeholder='Your Event version' name="date" type="text" value={state.date} onChange={handleChange} className={`${errorUpdate && error.date ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errorUpdate ? error.date : ""}</div>
                        </div>
                        <div className={Styles.input_wrapper}>
                            <input placeholder='Store domain eg: fa4e11-2.myshopify.com' name="link" type="text" value={state.link} onChange={handleChange} className={`${errorUpdate && error.link ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errorUpdate ? error.link : ""}</div>
                        </div>
                        <div className={Styles.input_row} style={{ gap: "20px" }}>
                            <button type="submit" disabled={disabled} className={Styles.theme_btn} onClick={handleClick}>
                                {/* {isSubmitting && disabled && <span className="spinner"></span>} */}
                                Import orders
                            </button>
                        </div>
                    </form>
                }
                {location?.loading &&
                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <Loader />
                    </div>
                }
                {location?.error &&
                    <div class="text-center m-5">
                        <div class="text-danger">Error loading user: {location.error}</div>
                    </div>
                }
            </div>
        </div>
    );
}
